import './rulesLibrary.scss'
import { Box, Link, TableContainer, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { RulesDrawer } from './RulesDrawer'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '@mui/x-data-grid'
import { TableChip } from '../../../utilities/TableChip'

const RulesLibraryTable = ({ rows, setIsLoading, isLoading, myRule, setInstanceRules, instanceRules }) => {
  const [rowsToShow, setRowsToShow] = useState([])
  const [selectedRule, setSelectedRule] = useState('')
  const [open, setOpen] = useState(false)
  const { t } = useTranslation(['common'])

  const { activeServices } = useSelector((state) => state.login)

  const verifyCration = () => {
    let aux = false
    activeServices?.map((path) => {
      if (path.includes('createClientInstanceRule')) aux = true
      return aux
    })
    return aux
  }

  useEffect(() => {
    if (rows.length !== 0) setRowsToShow([...rows.filter((item) => item.gueno)])
  }, [rows])

  const columns = [
    {
      field: 'id',
      headerName: t('common:ruleId'),
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => {
        return params.row.id
      },
    },
    {
      field: 'ruleName',
      headerName: t('common:ruleName'),
      flex: 1,
      minWidth: 400,
      renderCell: (params) => {
        return <Box>{params.row.ruleNameAlias || params.row.name}</Box>
      },
    },
    {
      field: 'nature',
      headerName: t('common:nature'),
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        return <Box>{params.row.nature && params.row.nature !== null ? params.row.nature : '---'}</Box>
      },
    },
    {
      field: 'ruleDescription',
      headerName: t('common:ruleDescription'),
      flex: 1,
      minWidth: 600,
      renderCell: (params) => {
        return (
          <Box>
            <Typography variant="subtitle3">{params.row.description}</Typography>
          </Box>
        )
      },
    },
    {
      field: 'action',
      headerName: t('common:action'),
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        return (
          <Link
            disabled={!verifyCration()}
            key={params.row.id}
            onClick={() => {
              setSelectedRule(params.row)
              setOpen(true)
            }}
          >
            {t('common:configure')}
          </Link>
        )
      },
    },
  ]

  return (
    <Box>
      <TableContainer>
        <DataGrid
          getRowId={(row) => row.id}
          rows={(Array.isArray(rowsToShow) && rowsToShow?.length > 0 && rowsToShow) || []}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
          getRowHeight={() => 'auto'}
        />
      </TableContainer>

      <RulesDrawer
        selectedRule={selectedRule}
        setSelectedRule={setSelectedRule}
        setOpen={setOpen}
        open={open}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        myRule={myRule}
        ruleToEdit={selectedRule}
        ruleList={instanceRules}
        setInstanceRules={setInstanceRules}
      />
    </Box>
  )
}

export default RulesLibraryTable
