import './selectSingle.scss'
import { useState, useEffect } from 'react'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import { mapCodesToNames } from '../../../../utilities/MapCodesToNames'
import { useTranslation } from 'react-i18next'

const SelectSingle = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  posibleValues,
  desc,
  isEditing,
  schema,
  filterTemplate,
}) => {
  const [selected, setSelected] = useState(schema?.default ?? null)
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common'])

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters.length === 0) return -1
    if (ruleParameters) return ruleParameters.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux) {
      setSelected(aux[propertie])
    }
  }, [propertie])

  const handleChange = (value) => {
    setSelected(value)
    setChange(true)
  }

  useEffect(() => {
    const index = getIndex()

    if (change) {
      let all = [...ruleParameters]
      let previous = index >= 0 && all[index] ? { ...all[index] } : {}

      previous[propertie] = selected
      previous.filterTemplate = filterTemplate

      if (index !== -1) {
        all.splice(index, 1)
      }
      all.push(previous)

      setRuleParameters([...all])
    }
    setChange(false)
  }, [selected])

  return (
    <Box className="select-single-container">
      {desc && <Typography variant="subtitle4">{desc}</Typography>}

      <Autocomplete
        options={posibleValues}
        getOptionLabel={(option) => mapCodesToNames(option)}
        filterSelectedOptions
        value={selected}
        disabled={!isEditing}
        onChange={(e, value) => handleChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
          />
        )}
      />
    </Box>
  )
}

export default SelectSingle
