import './checkbox.scss'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useState, useEffect } from 'react'

const CheckboxItem = ({ propertie, ruleParameters, setRuleParameters, label, text, isEditing }) => {
  const [value, setValue] = useState(false)
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (ruleParameters[propertie]) {
      setValue(ruleParameters[propertie])
    }
  }, [propertie, ruleParameters])

  const checkHandler = (item) => {
    setValue(item)
    setChange(true)
  }

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      all[propertie] = value
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [value, change])

  return (
    <Box className="checkbox-input-container">
      <Box className="checkbox-input-row">
        <FormControlLabel
          disabled={!isEditing}
          control={<Checkbox checked={value} />}
          label={<Typography variant="title3">{label}</Typography>}
          onChange={() => checkHandler(!value)}
        />
        {text && <Typography variant="subtitle4">{text}</Typography>}
      </Box>
    </Box>
  )
}

export default CheckboxItem
