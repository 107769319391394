import { useState } from 'react'
import { useEffect } from 'react'
import './selectMultiple.scss'
import { mapCodesToNames } from '../../../../utilities/MapCodesToNames'
import { useTranslation } from 'react-i18next'
import { Autocomplete, TextField } from '@mui/material'

const SelectMultiple = ({ propertie, ruleParameters, setRuleParameters, posibleValues, isEditing, filterTemplate }) => {
  const [selectedValue, setSelectedValue] = useState([])
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common', 'messages'])

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters?.length === 0) return -1
    if (Array.isArray(ruleParameters)) return ruleParameters?.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const aux = ruleParameters.find((e) => e.filterTemplate === filterTemplate)
    if (aux) {
      setSelectedValue(aux[propertie])
    }
  }, [propertie])

  const handleChange = (event, value) => {
    setSelectedValue(value)
    setChange(true)
  }

  useEffect(() => {
    const index = getIndex()

    if (change) {
      let all = [...ruleParameters]
      let previous = index >= 0 && all[index] ? { ...all[index] } : {}

      previous[propertie] = selectedValue
      previous.filterTemplate = filterTemplate

      if (index !== -1) {
        all.splice(index, 1)
      }
      all.push(previous)

      setRuleParameters([...all])
    }
    setChange(false)
  }, [selectedValue])

  return (
    <Autocomplete
      multiple
      options={posibleValues}
      getOptionLabel={(option) => mapCodesToNames(option)}
      filterSelectedOptions
      value={selectedValue}
      disabled={!isEditing}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
        />
      )}
    />
  )
}

export default SelectMultiple
