import { Box, Button, Fab, Modal, TableContainer, Tooltip, Typography } from '@mui/material'
import Switch from '@mui/material/Switch'
import { useState, useEffect } from 'react'
import { TableChip } from '../../../utilities/TableChip'
import { useTranslation } from 'react-i18next'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { DataGrid } from '@mui/x-data-grid'
import { ArchiveOutlined } from '@mui/icons-material'
import { MaterialUISwitch } from '../../../utilities/Switches'
import { useSelector } from 'react-redux'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const MyRulesTable = ({
  handler,
  rows,
  handleRuleUpdate,
  deleteHandler,
  handleArchive,
  isFetching,
  verifyEdition,
  verifyDeletion,
  handlerCopy,
}) => {
  const [rowsToShow, setRowsToShow] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [actionType, setActionType] = useState('')
  const [selectedRule, setSelectedRule] = useState({})
  const [message, setMessage] = useState('')
  const { t } = useTranslation(['common', 'messages'])
  const { theme: themeGueno } = useSelector((state) => state.theme)

  useEffect(() => {
    setRowsToShow([...rows])
  }, [rows])

  const handleAction = () => {
    switch (actionType) {
      case 'shadowMode':
        handleRuleUpdate(selectedRule.id, !selectedRule.isShadow, selectedRule, true, rows, setRowsToShow, 'isShadow')
        return handleClose()
      case 'duplicate':
        handlerCopy(selectedRule.id)
        return handleClose()
      case 'archive':
        handleArchive(selectedRule.id)
        return handleClose()
      case 'delete':
        deleteHandler(selectedRule.id)
        return handleClose()
      default:
        break
    }
  }

  const handleClose = () => {
    setModalOpen(false)
    setMessage('')
    setActionType('')
    setSelectedRule({})
  }

  const columns = [
    {
      field: 'id',
      headerName: t('common:active'),
      flex: 1,
      minWidth: 70,
      renderCell: (params) => {
        return verifyEdition ? (
          <Switch
            disabled={isFetching}
            checked={params.row.status === 'ACTIVE'}
            onChange={() =>
              handleRuleUpdate(
                params.row.id,
                params.row.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
                params.row,
                true,
                rows,
                setRowsToShow,
                'status',
              )
            }
            color="secondary"
          />
        ) : (
          <Box>{params.row.status}</Box>
        )
      },
    },
    {
      field: 'shadowMode1',
      headerName: t('common:shadowLive'),
      flex: 1,
      minWidth: 110,
      renderCell: (params) => {
        return verifyEdition ? (
          <Box className="search-buttons">
            <MaterialUISwitch
              disabled={isFetching}
              checked={params.row.isShadow}
              thememode={themeGueno}
              onClick={() => {
                setSelectedRule(params.row)
                setActionType('shadowMode')
                setMessage(t('messages:rules.shadowDesc'))
                setModalOpen(true)
              }}
            />
          </Box>
        ) : (
          <Box>{params.row.status}</Box>
        )
      },
    },
    {
      field: 'shadowMode',
      headerName: t('common:shadowMode'),
      flex: 1,
      hide: !process.env.REACT_APP_IS_DEMO,
      minWidth: 120,
      renderCell: (params) => {
        return verifyEdition ? <Switch checked={true} color="secondary" /> : <Box>{params.row.status}</Box>
      },
    },
    {
      field: 'ruleId',
      headerName: t('common:ruleId'),
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return `${params.row.ruleId} (${params.row.id})`
      },
    },
    {
      field: 'ruleName',
      headerName: t('common:ruleName'),
      flex: 1,
      minWidth: 350,
      renderCell: (params) => {
        return `${params.row.ruleNameAlias || params.row.name}`
      },
    },
    {
      field: 'ruleDescriptionAlias',
      headerName: t('common:ruleDescription'),
      flex: 1,
      minWidth: 450,
      renderCell: (params) => {
        return <Typography variant="subtitle3">{params.row.ruleDescriptionAlias ?? '---'}</Typography>
      },
    },
    {
      field: 'action',
      headerName: t('common:action'),
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <TableChip action={params.row.action} noIcon={true} />
      },
      valueGetter: (params) => {
        return params.row.action
      },
    },
    {
      field: 'hitRate',
      headerName: t('common:hitRate'),
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <Typography variant="subtitle1">
            {(params.row.runCount === 0 ? 0 : (params.row.hitCount * 100) / params.row.runCount).toFixed() + '%'}
          </Typography>
        )
      },
      valueGetter: (params) => {
        return (params.row.runCount === 0 ? 0 : (params.row.hitCount * 100) / params.row.runCount).toFixed()
      },
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box className="user-admin-flex">
            <Tooltip title={t('common:edit')}>
              <Button variant="outlinedButton" className="icon-fab" onClick={() => handler(params.row.id)}>
                <EditOutlinedIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t('common:duplicate')}>
              <Button
                variant="outlinedButtonSuccess"
                className="icon-fab"
                onClick={() => {
                  setSelectedRule(params.row)
                  setActionType('duplicate')
                  setMessage(t('messages:rules.duplicateDesc'))
                  setModalOpen(true)
                }}
              >
                <ContentCopyOutlinedIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t('common:archive')}>
              <Button
                variant="outlinedButtonAction"
                className="icon-fab"
                onClick={() => {
                  setSelectedRule(params.row)
                  setActionType('archive')
                  setMessage(t('messages:rules.archiveDesc'))
                  setModalOpen(true)
                }}
                disabled={params.row.status !== 'ACTIVE' && params.row.status !== 'INACTIVE'}
              >
                <ArchiveOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={t('common:delete')}>
              <Button
                variant="outlinedButtonError"
                className="icon-fab"
                onClick={() => {
                  setSelectedRule(params.row)
                  setActionType('delete')
                  setMessage(t('messages:rules.deleteDesc'))
                  setModalOpen(true)
                }}
                disabled={!verifyDeletion}
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </Tooltip>
          </Box>
        )
      },
    },
  ]

  return (
    <Box>
      <TableContainer>
        <DataGrid
          getRowId={(row) => row.id}
          getRowHeight={() => 'auto'}
          rows={(Array.isArray(rowsToShow) && rowsToShow?.length > 0 && rowsToShow) || []}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
        />
      </TableContainer>

      <Modal open={modalOpen} onClose={handleClose}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{`${t(`common:${actionType}`)} ${t('common:rule')}`}</Typography>
            </Box>
            <Fab variant="close" onClick={handleClose}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box-filters" sx={{ maxWidth: '35rem' }}>
            {message && <Box>{message}</Box>}
            {t('common:continue')}?
            <Box className="modal-filter-buttons">
              <Button size="large" variant="outlinedGrey" onClick={handleClose}>
                {t('common:cancel')}
              </Button>
              <Button size="large" variant="outlined" onClick={handleAction}>
                {t('common:Ok')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default MyRulesTable
