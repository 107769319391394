import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAnnualTransactions,
  updateMonthTransactions,
  updateTransactionByUserId,
  updateTransactionDetails,
  updateTransactionDetailsDestination,
  updateTransactionDetailsOrigin,
  updateTransactions,
} from '../../features/transactions/transactionSlice'
import { convertToUTCDate, formatDate } from '../../components/common/time/timeHelper'

export const useTransactions = (clientEmail, setIsLoading) => {
  const [rendered, setRendered] = useState(false)
  const { accessToken } = useSelector((state) => state.login)
  const transactionState = useSelector((state) => state.transaction)
  const queryState = useSelector((state) => state.transaction.filters)
  const {
    viewMode,
    limit,
    offset,
    page,
    fromDate,
    toDate,
    transactionId,
    transactionType,
    originCurrency,
    destinationCurrency,
    originMethod,
    destinationMethod,
    ruleId,
    ruleInstanceId,
    shadowRuleInstanceId,
    maxRuleAction,
    transactionKey,
    transactionValue,
    documentNumber,
    firstName,
    lastName,
    legalName,
  } = queryState
  const dispatch = useDispatch()

  useEffect(() => {
    if (rendered && !transactionState.transactions.length) {
      const baseUrlTransactions = `${process.env.REACT_APP_BASEURL}/kyt/transactions/${clientEmail}`

      let urlGetTransactions = buildUrl(baseUrlTransactions)

      function buildUrl(baseUrl) {
        let url = `${baseUrl}?viewMode=${viewMode}&limit=${limit}&page=${page}&offset=${offset}`
        if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
        if (ruleId?.length) url += `&ruleId=${ruleId}`
        if (transactionId) url += `&transactionId=${transactionId}`
        if (transactionType) url += `&transactionType=${transactionType}`
        if (originCurrency !== null) url += `&originCurrency=${originCurrency.aka}`
        if (destinationCurrency !== null) url += `&destinationCurrency=${destinationCurrency.aka}`
        if (originMethod !== null) url += `&originMethod=${originMethod}`
        if (destinationMethod !== null) url += `&destinationMethod=${destinationMethod}`
        if (ruleInstanceId) url += `&ruleInstanceId=${ruleInstanceId}`
        if (shadowRuleInstanceId) url += `&shadowRuleInstanceId=${shadowRuleInstanceId}`
        if (maxRuleAction) url += `&maxRuleAction=${maxRuleAction}`
        if (transactionKey) url += `&key=${transactionKey}`
        if (transactionValue) url += `&value=${transactionValue}`
        if (documentNumber) url += `&documentNumber=${documentNumber}`
        if (firstName) url += `&firstName=${firstName}`
        if (lastName) url += `&lastName=${lastName}`
        if (legalName) url += `&legalName=${legalName}`
        return url
      }

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(process.env.REACT_APP_IS_DEMO ? './data/transactions.json' : urlGetTransactions, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            const { data, pagination, unique } = res
            const aux = { data, pagination, unique }
            dispatch(updateTransactions(aux))
          }
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[HOOK: useTransactions] --> ', error)
          setIsLoading(false)
        })
    }
  }, [rendered, queryState])

  useEffect(() => {
    setRendered(true)
  }, [])

  return transactionState.transactions
}

export const useTransactionsByUserId = (clientEmail, userId) => {
  const [rendered, setRendered] = useState(false)
  const { accessToken } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.transaction.filters)
  const { viewMode, limit, offset, page } = queryState
  const dispatch = useDispatch()

  useEffect(() => {
    if (clientEmail && userId && rendered) {
      const baseUrlTransactions = `${process.env.REACT_APP_BASEURL}/kyt/transactions/${clientEmail}`

      let urlGetTransactions = buildUrl(baseUrlTransactions)

      function buildUrl(baseUrl) {
        let url = `${baseUrl}?viewMode=${viewMode}&limit=${limit}&page=${page}&offset=${offset}`
        if (userId) url += `&transactionUserId=${userId}`
        return url
      }

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../data/transactions.json' : urlGetTransactions, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            const { data, pagination } = res
            const aux = {
              data: process.env.REACT_APP_IS_DEMO
                ? data.filter((element) => {
                    return element.originUserId === userId || element.destinationUserId === userId
                  })
                : data,
              pagination,
            }
            dispatch(updateTransactionByUserId(aux))
          }
        })
        .catch((error) => {
          console.error('[HOOK: useTransactions] --> ', error)
        })
    }
  }, [rendered, queryState, userId])

  useEffect(() => {
    setRendered(true)
  }, [])
}

export const useTransactionDetails = (tId, setIsLoading) => {
  const [rendered, setRendered] = useState(true)
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const { transactionDetails } = useSelector((state) => state.transaction)
  const dispatch = useDispatch()

  useEffect(() => {
    if (rendered || tId !== transactionDetails?.transactionId) {
      setRendered(false)
      const baseUrlTransactions = `${process.env.REACT_APP_BASEURL}/kyt/retrieveTransaction/${tId}`

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../data/transaction.details.json' : baseUrlTransactions, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            dispatch(updateTransactionDetails(res.data))
            dispatch(updateTransactionDetailsOrigin({}))
            dispatch(updateTransactionDetailsDestination({}))
            if (res.data && res.data.originUserId) {
              const url = `${process.env.REACT_APP_BASEURL}/kyt/users/${res.data.originUserId}/${emailClient}`
              fetch(process.env.REACT_APP_IS_DEMO ? '../data/kyt.both.json' : url, options)
                .then((resOr) => resOr.json())
                .then((resOr) => {
                  if (resOr.data) {
                    resOr.data = process.env.REACT_APP_IS_DEMO
                      ? resOr.data?.filter((element) => {
                          return element.userId === res.data.originUserId
                        })[0]
                      : resOr.data
                    dispatch(updateTransactionDetailsOrigin(resOr.data))
                  } else {
                    throw new Error('User Not Found.')
                  }
                })
                .catch((error) => {
                  console.error('[HOOK: useTransactionDetailsOrigin] --> ', error)
                })
            }
            if (res.data && res.data.destinationUserId) {
              const url = `${process.env.REACT_APP_BASEURL}/kyt/users/${res.data.destinationUserId}/${emailClient}`
              fetch(process.env.REACT_APP_IS_DEMO ? '../data/kyt.both.json' : url, options)
                .then((resDest) => resDest.json())
                .then((resDest) => {
                  if (resDest.data) {
                    resDest.data = process.env.REACT_APP_IS_DEMO
                      ? resDest.data?.filter((element) => {
                          return element.userId === res.data.destinationUserId
                        })[0]
                      : resDest.data
                    dispatch(updateTransactionDetailsDestination(resDest.data))
                  } else {
                    throw new Error('User Not Found.')
                  }
                })
                .catch((error) => {
                  console.error('[HOOK: useTransactionDetailsdestination] --> ', error)
                })
            }
          }
        })
        .catch((error) => {
          console.error('[HOOK: useTransactionDetails] --> ', error)
        })

      setIsLoading(false)
    }
  }, [rendered])
}

export const useTransactionsMonthPercentage = (clientEmail) => {
  const [txs, setTxs] = useState([])
  const [rendered, setRendered] = useState(false)
  const [aux, setAux] = useState(false)
  const { accessToken, activeServices } = useSelector((state) => state.login)
  const transactionState = useSelector((state) => state.transaction)
  const dispatch = useDispatch()

  const validateMonth = () => {
    activeServices?.map((path) => {
      if (path.includes('getCurrentMonthTransactionsStatePercentage')) {
        setAux(true)
      }
    })
  }

  useEffect(() => {
    if (!rendered && clientEmail && txs.length === 0 && !transactionState.monthTransactions.length && aux) {
      buildTransactions()
      setRendered(true)
    }
  }, [rendered, txs, aux])

  useEffect(() => {
    validateMonth()
  }, [activeServices])

  async function buildTransactions() {
    const url = `${process.env.REACT_APP_BASEURL}/kyt/getCurrentMonthTransactionsStatePercentage/${clientEmail}`
    try {
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
      const res = await fetch(
        process.env.REACT_APP_IS_DEMO ? '../data/getCurrentMonthTransactionsStatePercentage.json' : url,
        options,
      )

      if (res.ok) {
        const resJson = await res.json()
        let aux = resJson.data
        if (aux.length !== 0) aux = aux.sort((a, b) => (a.percentage <= b.percentage ? 1 : -1))
        setTxs(aux)
        dispatch(updateMonthTransactions(aux))
      }
    } catch (error) {
      console.error('[GET MONTH TRANSACTIONS ERROR] -> ', error)
    }
  }
  return transactionState.monthTransactions
}

export const useAnnualTransactions = (clientEmail) => {
  const [rendered, setRendered] = useState(false)
  const [aux, setAux] = useState(false)
  const { accessToken, activeServices } = useSelector((state) => state.login)
  const transactionState = useSelector((state) => state.transaction)
  const dispatch = useDispatch()

  const dataPlot = () => {
    return [
      { month: 'jan', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
      { month: 'feb', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
      { month: 'mar', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
      { month: 'apr', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
      { month: 'may', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
      { month: 'jun', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
      { month: 'jul', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
      { month: 'aug', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
      { month: 'sep', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
      { month: 'oct', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
      { month: 'nov', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
      { month: 'dec', transactions: 0, 'consumer-users': 0, 'business-users': 0 },
    ]
  }

  const validateAnnual = () => {
    activeServices?.map((path) => {
      if (path.includes('getAnnualSummary')) {
        setAux(true)
      }
    })
  }

  function buildTransactions() {
    const url = `${process.env.REACT_APP_BASEURL}/kyt/getAnnualSummary/${clientEmail}`
    const today = new Date()
    try {
      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            let mapped = dataPlot()

            for (let i in mapped) {
              for (let k in res.data) {
                res.data[k].find((t) => {
                  if (t.date.split('-')[0].toString() === mapped[i]?.month.toString()) {
                    mapped[i][k] = t.numberoftransactions
                  }
                })
              }
            }

            dispatch(updateAnnualTransactions(getLastYearMonthsStructure(mapped, today)))
          }
        })
    } catch (error) {
      console.error('[GET ANNUAL SUMMARY ERROR] -> ', error)
    }
  }

  async function buildTransactionsDemo() {
    const url = `${process.env.REACT_APP_BASEURL}/kyt/getCurrentMonthTransactionsStatePercentage/${clientEmail}`
    try {
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
      const res = await fetch(
        process.env.REACT_APP_IS_DEMO ? '../data/getCurrentMonthTransactionsStatePercentage.json' : url,
        options,
      )

      if (res.ok) {
        let mapped = dataPlot()
        const today = new Date()

        for (let i in mapped) {
          for (let k in res.data) {
            res.data[k].find((t) => {
              if (t.date.split('-')[0].toString() === mapped[i]?.month.toString()) {
                mapped[i][k] = t.numberoftransactions
              }
            })
          }
        }

        dispatch(updateAnnualTransactions(getLastYearMonthsStructure(mapped, today)))
      }
    } catch (error) {
      console.error('[GET MONTH TRANSACTIONS ERROR] -> ', error)
    }
    return transactionState.monthTransactions
  }

  const getLastYearMonthsStructure = (data2Plot, actualDate) => {
    const aux = 11 - actualDate.getMonth()
    if (aux > 0) {
      for (let i = 0; i < aux; i++) {
        data2Plot.unshift(data2Plot[11])
      }
      data2Plot.splice(12, aux)
    }
    return data2Plot
  }

  useEffect(() => {
    setRendered(true)
  }, [])

  useEffect(() => {
    if (rendered && !transactionState.annualTransactions.length && aux) {
      if (process.env.REACT_APP_IS_DEMO) buildTransactionsDemo()
      else buildTransactions()
    }
  }, [rendered, aux])

  useEffect(() => {
    validateAnnual()
  }, [activeServices])

  return transactionState.annualTransactions
}

export const TransactionExport = (paramLimit, accessToken, queryStateTransaction, timezone) => {
  const {
    fromDate,
    toDate,
    transactionId,
    transactionType,
    transactionState,
    originUserId,
    destinationUserId,
    originCurrency,
    destinationCurrency,
    originMethod,
    destinationMethod,
    ruleId,
    ruleInstanceId,
    maxRuleAction,
    transactionKey,
    transactionValue,
    documentNumber,
    firstName,
    lastName,
    legalName,
  } = queryStateTransaction

  const urlExporReportTransactions = `${process.env.REACT_APP_BASEURL}/dataExport/retrieveData/transactions`

  function buildUrl(baseUrl) {
    let url = baseUrl
    let hasQueryParams = false

    if (paramLimit !== undefined) {
      url += `?limit=${paramLimit}`
      hasQueryParams = true
    }
    if (fromDate && toDate) {
      const fromDateStr = formatDate(convertToUTCDate(fromDate, timezone))
      const toDateStr = formatDate(convertToUTCDate(toDate, timezone))
      url += `${hasQueryParams ? '&' : '?'}fromDate=${fromDateStr}&toDate=${toDateStr}`
      hasQueryParams = true
    }
    if (ruleId?.length) {
      url += `${hasQueryParams ? '&' : '?'}ruleId=${ruleId}`
      hasQueryParams = true
    }
    if (transactionId) {
      url += `${hasQueryParams ? '&' : '?'}transactionId=${transactionId}`
      hasQueryParams = true
    }
    if (transactionType) {
      url += `${hasQueryParams ? '&' : '?'}transactionType=${transactionType}`
      hasQueryParams = true
    }
    if (transactionState) {
      url += `${hasQueryParams ? '&' : '?'}transactionState=${transactionState}`
      hasQueryParams = true
    }
    if (originUserId) {
      url += `${hasQueryParams ? '&' : '?'}originUserId=${originUserId}`
      hasQueryParams = true
    }
    if (destinationUserId) {
      url += `${hasQueryParams ? '&' : '?'}destinationUserId=${destinationUserId}`
      hasQueryParams = true
    }
    if (originCurrency) {
      url += `${hasQueryParams ? '&' : '?'}originCurrency=${originCurrency.aka}`
      hasQueryParams = true
    }
    if (destinationCurrency) {
      url += `${hasQueryParams ? '&' : '?'}destinationCurrency=${destinationCurrency.aka}`
      hasQueryParams = true
    }
    if (originMethod) {
      url += `${hasQueryParams ? '&' : '?'}originMethod=${originMethod}`
      hasQueryParams = true
    }
    if (destinationMethod) {
      url += `${hasQueryParams ? '&' : '?'}destinationMethod=${destinationMethod}`
      hasQueryParams = true
    }
    if (ruleInstanceId) {
      url += `${hasQueryParams ? '&' : '?'}ruleInstanceId=${ruleInstanceId}`
      hasQueryParams = true
    }
    if (maxRuleAction) {
      url += `${hasQueryParams ? '&' : '?'}maxRuleAction=${maxRuleAction}`
      hasQueryParams = true
    }
    if (transactionKey) {
      url += `${hasQueryParams ? '&' : '?'}key=${transactionKey}`
      hasQueryParams = true
    }
    if (transactionValue) {
      url += `${hasQueryParams ? '&' : '?'}value=${transactionValue}`
      hasQueryParams = true
    }
    if (documentNumber) {
      url += `${hasQueryParams ? '&' : '?'}documentNumber=${documentNumber}`
      hasQueryParams = true
    }
    if (firstName) {
      url += `${hasQueryParams ? '&' : '?'}firstName=${firstName}`
      hasQueryParams = true
    }
    if (lastName) {
      url += `${hasQueryParams ? '&' : '?'}lastName=${lastName}`
      hasQueryParams = true
    }
    if (legalName) {
      url += `${hasQueryParams ? '&' : '?'}legalName=${legalName}`
      hasQueryParams = true
    }
    return url
  }

  let urlGetExportrReportTransactions = buildUrl(urlExporReportTransactions)

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  fetch(process.env.REACT_APP_IS_DEMO ? './data/transactions.json' : urlGetExportrReportTransactions, options)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`)
      }
    })
    .catch((error) => {
      console.error('[HOOK: transactionExport] --> ', error)
    })
}
