import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, InputLabel, MenuItem, Modal, Select, Typography } from '@mui/material'
import ModalTitle from '../../../../../components/common/Modal/ModalTitle'
import { LoadingButton } from '@mui/lab'
import { useCreateKycPersonCheckMutation } from '../../../../../features/kyc/kycSlice'
import toaster from '../../../../../toaster'
import { countryDictionary } from '../../../../../components/common/flag/countries'
import { FileUploaderBox } from '../FileUploaderBox'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'

import styles from './styles.module.scss'

const DOCUMENT_TYPES = [
  'passport',
  'driving_license',
  'national_insurance_number',
  'social_security_number',
  'tax_identification_number',
  'utility_bill',
  'national_identity_card',
  'visa',
  'polling_card',
  'residence_permit',
  'birth_certificate',
  'bank_statement',
  'change_of_name',
  'tax_document',
  'company_confirmation_statement',
  'company_annual_accounts',
  'company_statement_of_capital',
  'company_change_of_address',
  'company_incorporation',
  'company_change_of_officers',
  'company_change_of_beneficial_owners',
  'unknown',
  'other',
]

const CHECK_TYPES = ['identity_check', 'document_check']

const FILE_TYPES = ['JPG', 'JPEG', 'PNG', 'GIF']

const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const schema = yup.object().shape({
  checkType: yup.string().required('Check Type is required'),
  documentType: yup.string().required('Document Type is required'),
  country: yup.string().required('Issuing Country is required'),
  selfie: yup
    .mixed()
    .nullable()
    .test('selfie-required', 'Selfie is required for identity check', function (value) {
      const { checkType } = this.parent
      return checkType !== 'identity_check' || !!value
    }),
  documentFront: yup.mixed().required('Document front side is required'),
  documentBack: yup.mixed().required('Document back side is required'),
})

export const CreateCheckModal = ({ id, modalOpen, setModalOpen }) => {
  const { t } = useTranslation(['common', 'messages'])

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      checkType: '',
      documentType: '',
      country: '',
      selfie: null,
      documentFront: null,
      documentBack: null,
    },
  })

  const [createNewCheck, { isLoading }] = useCreateKycPersonCheckMutation()

  const checkType = watch('checkType')

  const onSubmit = async (data) => {
    try {
      const [selfie, documentFront, documentBack] = await Promise.all([
        data.selfie ? fileToBase64(data.selfie) : null,
        data.documentFront ? fileToBase64(data.documentFront) : null,
        data.documentBack ? fileToBase64(data.documentBack) : null,
      ])

      await createNewCheck({
        id,
        body: {
          ...data,
          selfie,
          documentFront,
          documentBack,
        },
      }).unwrap()
      toaster.success(t('messages:createCommentSuccess'))
      reset()
      setModalOpen(false)
    } catch (error) {
      console.error('[CREATE NEW COMMENT ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:createCommentError'))
    }
  }

  const handleClose = () => {
    reset()
    setModalOpen(false)
  }

  return (
    <Modal open={modalOpen} onClose={() => handleClose()}>
      <Box className="modal">
        <ModalTitle setModalOpen={handleClose} title={`${t('common:create')} a ${t('common:check')}`} />
        <Box className="modal-box" width="750px">
          <Box className={styles.selectorsContainer}>
            <Box className="onb-manual-input">
              <InputLabel>{t('common:checkType')}</InputLabel>
              <Controller
                name="checkType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    size="small"
                    fullWidth
                    displayEmpty
                    error={!!errors.checkType}
                    onChange={(e) => field.onChange(e)}
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">{`${t('common:select')} ${t('common:checkType')}`}</Typography>
                    </MenuItem>
                    {CHECK_TYPES.map((name) => (
                      <MenuItem key={name} value={name}>
                        {t(`common:${name}`)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.checkType && <Typography color="error">{errors.checkType.message}</Typography>}
            </Box>

            <Box className="onb-manual-input">
              <InputLabel>{`${t('common:document')} ${t('common:type')}`}</InputLabel>
              <Controller
                name="documentType"
                control={control}
                render={({ field }) => (
                  <Select {...field} fullWidth displayEmpty size="small" error={!!errors.documentType}>
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">{`${t('common:select')} ${t('common:document')}`}</Typography>
                    </MenuItem>
                    {DOCUMENT_TYPES.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name?.replaceAll('_', ' ')}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.documentType && <Typography color="error">{errors.documentType.message}</Typography>}
            </Box>

            <Box className="onb-manual-input">
              <InputLabel>{t('common:country')}</InputLabel>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select {...field} size="small" fullWidth displayEmpty error={!!errors.country}>
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">{`${t('common:select')} ${t('common:country')}`}</Typography>
                    </MenuItem>
                    {Object.keys(countryDictionary).map((key) => (
                      <MenuItem key={key} value={key}>
                        {countryDictionary[key]}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.country && <Typography color="error">{errors.country.message}</Typography>}
            </Box>
          </Box>

          {checkType === 'identity_check' && (
            <Box className={styles.selfieContainer}>
              <Controller
                name="selfie"
                control={control}
                render={({ field }) => (
                  <FileUploaderBox
                    title={t('common:addASelfie')}
                    file={field.value}
                    setFile={field.onChange}
                    fileTypes={FILE_TYPES}
                  />
                )}
              />
              {errors.selfie && <Typography color="error">{errors.selfie.message}</Typography>}
            </Box>
          )}

          <Box className={styles.documentContainer}>
            <Box width="45%">
              <Controller
                name="documentFront"
                control={control}
                render={({ field }) => (
                  <FileUploaderBox
                    title={t('common:documentFrontSide')}
                    file={field.value}
                    setFile={field.onChange}
                    fileTypes={FILE_TYPES}
                  />
                )}
              />
              {errors.documentFront && <Typography color="error">{errors.documentFront.message}</Typography>}
            </Box>

            <Box width="45%">
              <Controller
                name="documentBack"
                control={control}
                render={({ field }) => (
                  <FileUploaderBox
                    title={t('common:documentBackSide')}
                    file={field.value}
                    setFile={field.onChange}
                    fileTypes={FILE_TYPES}
                  />
                )}
              />
              {errors.documentBack && <Typography color="error">{errors.documentBack.message}</Typography>}
            </Box>
          </Box>
        </Box>

        <Box className="modal-button" width="200px" alignSelf="end">
          <LoadingButton
            size="small"
            color="secondary"
            variant="contained"
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {t('common:create')}
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  )
}
