import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { RenderTooltip } from '../../../../../components/utilities/RenderTooltip'

import styles from './styles.module.scss'
import { useMemo } from 'react'

const renderDate = (obj) => {
  return `${obj.day}/${obj.month}/${obj.year}`
}

const processField = (data) => {
  if (!data) return '---'
  if (Array.isArray(data)) return data.join(' ')
  if (typeof data === 'object') return renderDate(data)
  return data
}

const CheckLegalDocuments = ({ data }) => {
  const { t } = useTranslation(['common', 'messages'])

  const checkLegalDocuments = useMemo(() => {
    if (data?.result) {
      const visualData = data.result.breakdown?.extractedData.allExtractedData.visual || {}
      const mrzData = data.result.breakdown?.extractedData.allExtractedData.mrz || {}
      const barcodeData = data.result.breakdown?.extractedData.allExtractedData.barcode || {}

      const fields = Object.keys(visualData)

      const resultArray = fields.map((field) => {
        return {
          field: field,
          visual: visualData[field] || null,
          mrz: mrzData[field] || null,
          barcode: barcodeData[field] || null,
        }
      })

      return resultArray
    }

    return null
  }, [data])

  if (!checkLegalDocuments) return null

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('common:field')}</TableCell>
            <TableCell>
              <Box className={styles.tableTitle}>
                {t('common:visual')}

                <RenderTooltip title={t('messages:kyc.check.visual.tooltip')} />
              </Box>
            </TableCell>
            <TableCell>
              <Box className={styles.tableTitle}>
                MRZ
                <RenderTooltip title={t('messages:kyc.check.mrz.tooltip')} />
              </Box>
            </TableCell>
            <TableCell>
              <Box className={styles.tableTitle}>
                {t('common:barcode')}

                <RenderTooltip title={t('messages:kyc.check.barcode.tooltip')} />
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {checkLegalDocuments.map((item) => (
            <TableRow key={item.field}>
              <TableCell>{t(`messages:kyc.check.legalDocuments.${item.field}`)}</TableCell>
              <TableCell>{processField(item.visual)}</TableCell>
              <TableCell>{processField(item.mrz)}</TableCell>
              <TableCell>{processField(item.barcode)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CheckLegalDocuments
