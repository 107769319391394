import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Button, Chip, Paper, Tab, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useGetKycPersonQuery, useUpdateKycPersonMutation } from '../../../features/kyc/kycSlice'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import GridItem from './components/GridItem'
import styles from './styles.module.scss'
import { CheckDetails } from './components/CheckDetails'
import { CreateCommentModal } from './components/CreateCommentModal'
import { CreateCheckModal } from './components/CreateCheckModal'
import EditFieldModal from './components/EditFieldModal'
import toaster from '../../../toaster'
import { KycChip } from './components/KycChip'

const kycPersonStatus = ['APPROVED', 'REJECTED', 'FLAGGED', 'PENDING']

const getStyles = (type) => {
  let color
  let backgroundColor

  switch (type.toLowerCase()) {
    case 'creation':
    case 'creation_comment':
    case 'creation_check':
      backgroundColor = '#4184c3'
      color = '#fafafa'
      break
    case 'complete':
    case 'success':
      backgroundColor = '#00B85F'
      color = '#FBFFFC'
      break
    case 'error':
      backgroundColor = '#dc3545'
      color = '#FFFFFF'
      break
    default:
      backgroundColor = '#FF8A00'
      color = '#FBFFFC'
      break
  }

  return { color, backgroundColor }
}

export const KycPerson = () => {
  const { id } = useParams()
  const { t } = useTranslation(['common', 'messages'])
  const { theme } = useSelector((state) => state.theme)
  const [selectedTab, setSelectedTab] = useState('identity_check')
  const [selectedCheck, setSelectedCheck] = useState()
  const [modalNewCommentOpen, setModalNewCommentOpen] = useState(false)
  const [modalCreateCheckOpen, setModalCreateCheckOpen] = useState(false)
  const [modalEditField, setModalEditField] = useState(false)
  const [selectedField, setSelectedField] = useState({ label: '', value: '' })

  const { data } = useGetKycPersonQuery({ id })
  const kycPerson = data?.data

  const [updateKycPerson] = useUpdateKycPersonMutation({ fixedCacheKey: 'kyc-person-update' })

  const handleUpdateKycPerson = async (data) => {
    try {
      await updateKycPerson({
        id,
        body: data,
      }).unwrap()
      toaster.success(t('messages:updateSuccess'))
      setModalEditField(false)
    } catch (error) {
      console.error('[UPDATE KYC PERSON ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:updateError'))
    }
  }

  const timelineList = useMemo(() => {
    const timeline = kycPerson?.timeline || []
    return [...timeline].reverse()
  }, [kycPerson?.timeline])

  const handleTabChange = (tabValue) => {
    setSelectedCheck(null)
    setSelectedTab(tabValue)
  }

  const handleOpenModalEditField = useCallback(
    (label, value) => {
      setSelectedField({ label, value: value ?? '' })
      setModalEditField(true)
    },
    [setModalEditField],
  )

  return (
    <Box className={styles.container}>
      <Box className={styles.fullWidthGrid}>
        <Paper elevation={0} className={`${styles.paperStyle} ${styles.customPaper}`}>
          <Typography variant="h4" className={styles.pageTitle}>
            {kycPerson
              ? `${kycPerson?.firstname.toUpperCase()} ${kycPerson?.lastname.toUpperCase()} (${kycPerson?.email.toUpperCase()})`
              : ''}
          </Typography>
          <Box className={styles.gridItems}>
            <GridItem
              name={t('common:status')}
              value={kycPerson?.status ? t(`messages:kyc.person.status.${kycPerson?.status}`) : '---'}
              editable
              onEditClick={() => handleOpenModalEditField('status', kycPerson?.status)}
            />
            <GridItem
              name={t('common:outcome')}
              value={kycPerson?.outcome ? t(`messages:kyc.person.outcome.${kycPerson?.status}`) : '---'}
            />
            <GridItem
              name={t('common:createdAt')}
              value={kycPerson?.createdAt ? buildTimeStringFromTimestamp(kycPerson.createdAt) : '---'}
            />
            <GridItem
              name={t('common:updatedAt')}
              value={kycPerson?.updatedAt ? buildTimeStringFromTimestamp(kycPerson.updatedAt) : '---'}
            />
            <GridItem
              name={t('messages:kyc.person.externalId')}
              value={kycPerson?.externalId ?? '---'}
              editable
              onEditClick={() => handleOpenModalEditField('externalId', kycPerson?.externalId)}
            />
          </Box>
        </Paper>
        <Box>
          <Button
            className={styles.buttonContainer}
            size="small"
            onClick={() => setModalCreateCheckOpen(true)}
            variant="contained"
          >
            {`${t('common:create')} ${t('common:check')}`}
          </Button>
          <Button
            className={styles.buttonContainer}
            size="small"
            onClick={() => setModalNewCommentOpen(true)}
            variant="outlined"
          >
            {t('common:postComment')}
          </Button>
        </Box>
      </Box>
      <Box className={styles.fullWidthGrid}>
        <Box className={styles.paperStyle}>
          <TabContext value={selectedTab}>
            <TabList
              TabIndicatorProps={{ style: { display: 'none' } }}
              onChange={(e, newValue) => handleTabChange(newValue)}
              className={`${styles.tabList} ${
                theme === 'light' ? styles.tabListScrollbarLight : styles.tabListScrollbarDark
              }`}
              classes={{ flexContainer: styles.tabListFlexContainer }}
            >
              <Tab
                label={
                  <Chip
                    label={t('common:identity')}
                    sx={{ fontSize: 16 }}
                    variant={selectedTab === 'identity_check' ? 'sliderBlack' : 'sliderWhite'}
                  />
                }
                className={styles.tabMaxWidth}
                value="identity_check"
                disableRipple
              />
              <Tab
                label={
                  <Chip
                    label={t('common:document')}
                    sx={{ fontSize: 16 }}
                    variant={selectedTab === 'document_check' ? 'sliderBlack' : 'sliderWhite'}
                  />
                }
                className={styles.tabMaxWidth}
                value="document_check"
                disableRipple
              />
            </TabList>
            <Box>
              <TabPanel value={selectedTab}>
                <CheckDetails
                  id={id}
                  checkType={selectedTab}
                  selectedCheck={selectedCheck}
                  setSelectedCheck={setSelectedCheck}
                />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
        <Box>
          <Paper elevation={0} className={styles.customPaper}>
            <Typography variant="h4" mb={2}>
              {t('common:timelineAndActivity')}
            </Typography>
            {Array.isArray(timelineList) && (
              <Box
                className="timeline-wrapper"
                sx={{ display: 'flex', flexDirection: 'column', gap: '16px', maxHeight: '640px', overflowY: 'auto' }}
              >
                {timelineList.map((timeline) => {
                  const { color: colorType, backgroundColor: backgroundColorType } = getStyles(timeline?.type)
                  const { color: colorStatus, backgroundColor: backgroundColorStatus } = getStyles(timeline?.status)
                  return (
                    <Paper key={timeline._id} className="timeline-card" elevation={0} variant="colorSecondary">
                      <Box className="timeline-box" mb={2}>
                        <Box>
                          <KycChip
                            action={t(`messages:kyc.${timeline.type}`)}
                            color={colorType}
                            backgroundColor={backgroundColorType}
                          />
                        </Box>
                      </Box>
                      {buildTimeStringFromTimestamp(timeline.createdAt)}
                      <Box className="timeline-box" mt={2}>
                        <p>{t('common:status')}:</p>
                        <KycChip
                          action={t(`common:${timeline.status}`)}
                          color={colorStatus}
                          backgroundColor={backgroundColorStatus}
                        />
                      </Box>
                      <Box mt={2} className="timeline-box">
                        <p>{t('common:operationalAgent')}:</p>
                        <Typography variant="text2">{timeline.email}</Typography>
                      </Box>
                    </Paper>
                  )
                })}
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
      <CreateCommentModal id={id} modalOpen={modalNewCommentOpen} setModalOpen={setModalNewCommentOpen} />
      <CreateCheckModal id={id} modalOpen={modalCreateCheckOpen} setModalOpen={setModalCreateCheckOpen} />
      <EditFieldModal
        modalOpen={modalEditField}
        setModalOpen={setModalEditField}
        field={selectedField.label}
        value={selectedField.value}
        options={selectedField.label === 'status' ? kycPersonStatus : null}
        onSubmit={handleUpdateKycPerson}
      />
    </Box>
  )
}
