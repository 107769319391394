import './selectSingle.scss'
import { useState, useEffect } from 'react'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import { mapCodesToNames } from '../../../../utilities/MapCodesToNames'
import { useTranslation } from 'react-i18next'

const SelectSingle = ({ propertie, ruleParameters, setRuleParameters, posibleValues, desc, isEditing, schema }) => {
  const [selected, setSelected] = useState(null)
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (ruleParameters[propertie]) {
      setSelected(ruleParameters[propertie])
    }
  }, [propertie, ruleParameters])

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      all[propertie] = selected

      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [change])

  const handleChange = (value) => {
    setSelected(value)
    setChange(true)
  }

  return (
    <Box className="select-single-container">
      {desc && <Typography variant="subtitle4">{desc}</Typography>}

      <Autocomplete
        options={posibleValues}
        getOptionLabel={(option) => mapCodesToNames(option)}
        filterSelectedOptions
        value={selected}
        disabled={!isEditing}
        onChange={(e, value) => handleChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
          />
        )}
      />
    </Box>
  )
}

export default SelectSingle
