import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography, Paper, Fab, Chip, Tab, Button, Modal, IconButton } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useTranslation } from 'react-i18next'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined'
import { FileDownloadOutlined } from '@mui/icons-material'
import { TableIcons } from '../../../components/utilities/TableIcons'
import { TableChip } from '../../../components/utilities/TableChip'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import Spinner from '../../../components/common/spinner/spinner'

import '../batch.scss'
import ModalUpdateBatch from '../components/ModalUpdateBatch'
import DatatableBatchTemporalItems from '../../../components/common/datatable/DatatableBatchTemporalItems'
import { useSelector } from 'react-redux'
import { useGetBatchByIdQuery } from '../../../services/guenoApi'

function BatchDetails() {
  const { accessToken } = useSelector((state) => state.login)
  const { t } = useTranslation(['common'])
  const { id } = useParams()
  const { data, isLoading, isFetching, refetch } = useGetBatchByIdQuery({ id })
  const selectedBatch = data?.batch
  const [selectedTab, setSelectedTab] = useState('origin')
  const [showUpdateBatch, setShowUpdateBatch] = useState(false)
  const [show, setShow] = useState(false)

  const handleProcessBatch = async () => {
    setShow(false)
    const urlForProcessBatch = `${process.env.REACT_APP_BASEURL}/batch/process/${selectedBatch?.id}`

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    try {
      fetch(urlForProcessBatch, options).catch((err) => {
        throw new Error(err.message || 'Unexpected error creating batch')
      })
    } catch (error) {
      console.error('[HOOK: useBatches | processBatch] --> ', error)
    }
  }

  const updateBatch = async (file) => {
    const urlForCorrectionFile = `${process.env.REACT_APP_BASEURL}/batch/correctionFile/${selectedBatch?.id}`

    const formData = new FormData()
    formData.append('file', file)
    const options = {
      method: 'PATCH',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    try {
      fetch(urlForCorrectionFile, options).catch((err) => {
        throw new Error(err.message || 'Unexpected error updating batch')
      })
    } catch (error) {
      console.error('[HOOK: useBatches | sendCorrectionFile] --> ', error)
    }
  }

  const handleUpdateBatch = () => {
    setShowUpdateBatch(true)
  }

  const openFileUrl = (url) => {
    window.open(url, '_blank')
  }

  return (
    <Box className={`${(isLoading || isFetching) && 'spinner-transition'} filter`}>
      {(isLoading || isFetching) && <Spinner />}
      <ModalUpdateBatch
        showUpdateBatch={showUpdateBatch}
        setShowUpdateBatch={setShowUpdateBatch}
        updateBatch={updateBatch}
        batchId={selectedBatch?.id}
      />
      <Box className="batch-details">
        <Paper elevation={0} className="batch-details-top">
          <Typography variant="title">{t('common:BATCH_DETAILS')}</Typography>

          <Box className="batch-info">
            <Paper elevation={0} variant="colorPrimary" className="batch-box">
              <Box className="batch-id">
                <Box>
                  <Typography variant="subtitle1">{t('common:BATCH_ID')}</Typography>
                  <Typography variant="number">{selectedBatch?.id}</Typography>
                </Box>
                <Fab
                  size="small"
                  className="icon-global"
                  variant="action"
                  onClick={() => {
                    navigator.clipboard.writeText(selectedBatch?.id)
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </Fab>
              </Box>
            </Paper>
            <Paper
              elevation={0}
              variant="colorPrimary"
              className="batch-box"
              style={{ display: 'flex', width: '100%', flexDirection: 'row', gap: 16 }}
            >
              <Box>
                <Typography variant="subtitle1">{t('common:BATCH_TYPE')}</Typography>
                <Box sx={{ width: 'fit-content' }}>
                  <TableIcons type={selectedBatch?.type ?? '---'} />
                </Box>
              </Box>
              {selectedBatch?.type === 'TRANSACTION' && (
                <Typography variant="subtitle1">
                  {`(${t('messages:batch.skipErrors')}: ${
                    selectedBatch.options?.skipErrors === true ? t('common:yes') : t('common:no')
                  })`}
                </Typography>
              )}
            </Paper>
            <Paper elevation={0} variant="colorPrimary" className="batch-box">
              <Typography variant="subtitle1">{t('common:lastUpdated')}</Typography>
              <Typography variant="number">{buildTimeStringFromTimestamp(selectedBatch?.updatedAt)}</Typography>
            </Paper>
            <Paper elevation={0} variant="colorPrimary">
              <Box display="flex" justifyContent="space-between">
                <Box className="batch-box">
                  <Typography variant="subtitle1">{t('common:status')}</Typography>
                  <Typography variant="number">
                    <TableChip noIcon action={`BATCH_${selectedBatch?.status}` || '---'} />
                  </Typography>
                </Box>
                <Box className="batch-flex">
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => setShow(true)}
                    disabled={!['READY', 'ERROR'].includes(selectedBatch?.status)}
                    fullWidth
                  >
                    {t('common:PROCESS_BATCH')}
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => handleUpdateBatch()}
                    disabled={!['READY', 'ERROR'].includes(selectedBatch?.status)}
                    fullWidth
                  >
                    {t('common:CORRECT_BATCH')}
                  </Button>
                  <IconButton onClick={refetch} disabled={isLoading || isFetching} title={t('common:refresh')}>
                    <RefreshIcon />
                  </IconButton>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Paper>

        <TabContext value={selectedTab}>
          <Box className="batch-details-bottom">
            <Paper elevation={0} variant="rootTransparent" className="batch-details-top">
              <Typography variant="title">{t('common:sections')}</Typography>
              <TabList
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
                className="batch-tablist"
                onChange={(e, newValue) => setSelectedTab(newValue)}
              >
                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'origin' ? <CompareArrowsOutlinedIcon /> : ''}
                      label={t('common:BATCH_FILES')}
                      variant={selectedTab === 'origin' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="origin"
                />
                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'temporalItems' ? <AccessTimeIcon /> : ''}
                      label={t('common:TEMPORAL_ITEMS')}
                      variant={selectedTab === 'temporalItems' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="temporalItems"
                />
              </TabList>
            </Paper>
            <Paper elevation={0}>
              <TabPanel value="origin" className="batch-details-top">
                <Typography variant="title">{t('common:BATCH_FILES')}</Typography>

                <Box className="batch-info-bottom">
                  <Paper elevation={0} variant="rootDashed" className="batch-info-box">
                    <Box className="batch-id">
                      <Typography variant="title">{t('common:UPLOADED_FILES')}</Typography>
                    </Box>

                    <Box className="batch-info-button-list">
                      {selectedBatch?.files &&
                        selectedBatch?.files.map((file) => {
                          return (
                            <Paper
                              key={`${file.id}`}
                              onClick={() => openFileUrl(file.path)}
                              className="batch-box-center"
                              elevation={0}
                              variant="colorTertiary"
                            >
                              <Typography variant="subtitle3" className="file-truncated-name">
                                {file.originalName}
                              </Typography>
                              <FileDownloadOutlined key={file.id} color="disabled" />
                            </Paper>
                          )
                        })}
                    </Box>
                  </Paper>
                  {selectedBatch?.processResultFile && (
                    <Paper elevation={0} variant="rootDashed" className="batch-info-box">
                      <Box className="batch-id">
                        <Typography variant="title">{t('common:PROCESSED_RESULT_FILE')}</Typography>
                      </Box>

                      <Box className="batch-info-button-list">
                        <Paper
                          onClick={() => openFileUrl(selectedBatch?.processResultFile.path)}
                          className="batch-box-center"
                          elevation={0}
                          variant="colorTertiary"
                        >
                          <Typography variant="subtitle3" className="file-truncated-name">
                            {selectedBatch?.processResultFile.name}
                          </Typography>
                          <FileDownloadOutlined color="disabled" />
                        </Paper>
                      </Box>
                    </Paper>
                  )}
                  {selectedBatch?.wrongItemsFiles && selectedBatch?.wrongItemsFiles.length > 0 && (
                    <Paper elevation={0} variant="rootDashed" className="batch-info-box">
                      <Box className="batch-id">
                        <Typography variant="title">{t('common:WRONG_FILES')}</Typography>
                      </Box>

                      <Box className="batch-info-button-list">
                        {selectedBatch?.wrongItemsFiles.map((file) => (
                          <Paper
                            key={`${file.key}`}
                            onClick={() => openFileUrl(file.path)}
                            className="batch-box-center"
                            elevation={0}
                            variant="colorTertiary"
                          >
                            <Typography variant="subtitle3" className="file-truncated-name">
                              {file.name}
                            </Typography>
                            <FileDownloadOutlined color="disabled" />
                          </Paper>
                        ))}
                      </Box>
                    </Paper>
                  )}
                </Box>
              </TabPanel>
              <TabPanel value="temporalItems" className="batch-details-top">
                <Typography variant="title">{t('common:TEMPORAL_ITEMS')}</Typography>

                <DatatableBatchTemporalItems id={id} internalType={selectedBatch?.type} />
              </TabPanel>
            </Paper>
          </Box>
        </TabContext>
      </Box>
      <Modal open={show} onClose={() => setShow(false)}>
        <Box className="modal">
          <Box className="modal-top-batch">
            <Box className="modal-titles-batch">
              <Typography variant="title">Batch process</Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <p>
                {selectedBatch?.status !== 'READY'
                  ? t('messages:batch.process.batch.with.errors')
                  : t('messages:batch.process.batch.without.errors')}
              </p>
            </Box>
            <Box className="modal-title-batch">
              <Button onClick={() => setShow(false)}>{t('common:close')}</Button>
              <Button onClick={() => handleProcessBatch()}>
                {selectedBatch?.status !== 'READY' ? t('messages:batch.process.anyway') : t('common:PROCESS_BATCH')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default BatchDetails
