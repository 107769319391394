import { useEffect, useRef, useState } from 'react'
import { Box, Fab, IconButton, Link, Modal, Paper, Typography } from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import EditOutlined from '@mui/icons-material/EditOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

const GridItem = ({ name, value, copyable = false, editable = false, onEditClick = () => {}, icon }) => {
  const { t } = useTranslation(['common', 'messages'])
  const componentValueRef = useRef(null)
  const [openExtraData, setOpenExtraData] = useState(false)
  const [enableShowMore, setEnableShowMore] = useState(false)

  useEffect(() => {
    if (componentValueRef.current) {
      const { scrollHeight, clientHeight } = componentValueRef.current
      setEnableShowMore(scrollHeight > clientHeight)
    }
  }, [value])

  return (
    <Paper elevation={0} variant="colorPrimarySmall">
      <Box className={styles.extraContainer}>
        <Typography variant="subtitle1">{name}</Typography>
        {icon}
        {enableShowMore && (
          <Link onClick={() => setOpenExtraData(true)}>
            <Typography variant="number">{t('common:showMore')}</Typography>
          </Link>
        )}
      </Box>
      {!!value && (
        <Box className={styles.extraContainer}>
          <Typography
            variant="h5"
            ref={componentValueRef}
            sx={{ maxHeight: '2.5rem', overflow: 'hidden' }}
            style={{ whiteSpace: 'pre-line' }}
          >
            {value}
          </Typography>
          {copyable && (
            <IconButton style={{ padding: '0' }} onClick={() => navigator.clipboard.writeText(value)}>
              <ContentCopyOutlinedIcon style={{ height: '1rem', width: '1rem', fill: 'grey' }} />
            </IconButton>
          )}
          {editable && (
            <IconButton style={{ padding: '0' }} onClick={onEditClick}>
              <EditOutlined color="primary" style={{ height: '1rem', width: '1rem' }} />
            </IconButton>
          )}
        </Box>
      )}
      <Modal
        open={openExtraData}
        onClose={() => setOpenExtraData(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{name}</Typography>
            </Box>
            <Fab variant="close" onClick={() => setOpenExtraData(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>
          <Box className="modal-box">
            <Typography style={{ whiteSpace: 'pre-line' }}>{value}</Typography>
          </Box>
        </Box>
      </Modal>
    </Paper>
  )
}

export default GridItem
