/* eslint-disable no-self-assign */
import { useParams } from 'react-router-dom'
import './kyb.scss'
import { useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Fab,
  FormControlLabel,
  Link,
  Paper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import '../../components/common/alertFetchSpinner/alertFetchSpinner.scss'
import { Status } from '../../components/utilities/Status'
import { KybChip } from '../../components/utilities/KybChip'
import { Accordion, AccordionDetails, AccordionSummary } from '../../components/common/Accordion/Accordion'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { buildTimeStringFromTimestamp } from '../../components/common/time/timeHelper'
import KybToPdf from './components/KybToPdf'
import DatatableAssignUsers from '../../components/common/datatable/DatatableAssignedUsers'
import { useTranslation } from 'react-i18next'
import { RiskLevelSlider } from '../../components/utilities/RiskLevelSlider'
import { StatusIndicator } from '../../components/utilities/StatusIndicator'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { FileUploader } from 'react-drag-drop-files'
import { camelCaseFormatter } from '../../components/utilities/formatters/camelCase'
import ProcessingSpinner from '../../components/common/alertFetchSpinner/processingSpinner'
import CustomNoRowsOverlay from '../../components/common/datagrid/CustomNoRowsOverlay'
import { addAdditionalParams, getCountrySegment, removeLastWord } from './kyb.hook-helpers'
import MapComponent from '../../components/common/googleMaps/GoogleMapsComponent'
import KybPreviousChecks from './components/KybPreviousChecks'
import KybCheckDetail from './components/KybCheckDetail'
import TabComponent from './components/TabComponent'
import KybClusterModal from './components/KybClusterModal'
import {
  useCheckKybMutation,
  useCreateNewCommentBusinessMutation,
  useDeleteFileBusinessMutation,
  useFindAvailableUsersQuery,
  useFindKybBusinessByIdQuery,
  useFindUserMasterQuery,
  useRestartKybMutation,
  useUpdateCheckMutation,
  useUpdateIsPrivateKybMutation,
  useUpdateKybMutation,
  useUpdateRiskLevelMutation,
  useUploadFileBusinessMutation,
} from '../../services/guenoApi'
import KybChecks from './components/KybChecks.json'
import KybChecksDefaultOrder from './components/KybChecksDefaultOrder.json'
import KybTitle from './components/KybTitle'
import { colorsByStatus } from './constants'
import KybEditFieldModal from './components/KybEditFieldModal'
import toaster from '../../toaster'
import KybModal from './components/KybModal'
import KybEditRiskLevelModal from './components/KybEditRiskLevelModal'
import KybEditCheckStatusModal from './components/KybEditCheckStatusModal'
import KybEditStatusModal from './components/KybEditStatusModal'
import KybNewCommentModal from './components/KybNewCommentModal'
import KybResetModal from './components/KybResetModal'
import { deepGetByPath } from './utils'

const getStyles = (type) => {
  let color
  let backgroundColor

  switch (type.toLowerCase()) {
    case 'creation':
      backgroundColor = '#4184c3'
      color = '#fafafa'
      break
    case 'complete':
      backgroundColor = '#00B85F'
      color = '#FBFFFC'
      break
    default:
      backgroundColor = '#FF8A00'
      color = '#FBFFFC'
      break
  }

  return { color, backgroundColor }
}

const AttributesByCountry = {
  BR: {
    fantasyName: 'companyDetails.fantasia',
    dateOfRegistration: 'companyDetails.dateOfRegistration',
    status: 'companyDetails.situacao.nome',
    socialCapital: 'companyDetails.capitalSocial',
    companyLegalType: 'companyDetails.naturezaJuridica.descricao',
    companyType: '',
    size: 'companyDetails.porte.descricao',
    companyPrimaryCategoryCode: 'companyDetails.cnae.subClasse',
    companyPrimaryCategoryDescription: 'companyDetails.cnae.descricao',
    simplesNacional: 'companyDetails.simplesNacional.optante',
  },
  CO: {
    fantasyName: '',
    dateOfRegistration: 'companyDetails.dateOfRegistration',
    status: 'companyDetails.status',
    socialCapital: '',
    companyLegalType: 'companyDetails.legalOrganization',
    companyType: 'companyDetails.type',
    size: '',
    companyPrimaryCategoryCode: 'companyDetails.companyPrimaryCategory.code',
    companyPrimaryCategoryDescription: 'companyDetails.companyPrimaryCategory.description',
    simplesNacional: '',
  },
  AR: {
    fantasyName: '',
    dateOfRegistration: 'companyDetails.dateOfRegistration',
    status: 'companyDetails.status',
    socialCapital: '',
    companyLegalType: '',
    companyType: 'companyDetails.type',
    size: '',
    companyPrimaryCategoryCode: 'companyDetails.companyPrimaryCategory.code',
    companyPrimaryCategoryDescription: 'companyDetails.companyPrimaryCategory.description',
    simplesNacional: '',
  },
}

const KybDetailBusiness = () => {
  const { id, country } = useParams()
  const { t } = useTranslation(['common', 'messages'])
  const currentCountry = country.toUpperCase()
  const languageState = useSelector((state) => state.language.lang)
  const { emailClient, email, roles } = useSelector((state) => state.login)
  const { theme } = useSelector((state) => state.theme)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [modalNewCommentOpen, setModalNewCommentOpen] = useState(false)
  const [modalEditStatus, setModalEditStatus] = useState(false)
  const [openModalCheckStatus, setOpenModalCheckStatus] = useState(false)
  const [modalEditRiskLevel, setModalEditRiskLevel] = useState(false)
  const [modalEditCluster, setModalEditCluster] = useState(false)
  const [openModalEditField, setOpenModalEditField] = useState(false)
  const [modalEditField, setModalEditField] = useState({ label: '', value: '' })
  const [openModalKybReset, setOpenModalKybReset] = useState(false)
  const [checkId, setCheckId] = useState('')
  const [checkStatus, setCheckStatus] = useState('')
  const [checkType, setCheckType] = useState('')
  const [isEditingAssign, setIsEditingAssign] = useState(false)
  const [selectedTab, setSelectedTab] = useState('checks')
  const [coordinates, setCoordinates] = useState(null)
  const countrySegment = useMemo(() => getCountrySegment(currentCountry), [currentCountry])

  const { data: userMasters } = useFindUserMasterQuery({ emailClient })
  const { data, isFetching, isLoading } = useFindKybBusinessByIdQuery({
    id,
    languageState,
    countrySegment,
  })
  const kybBusiness = data?.data
  const [restartKyb] = useRestartKybMutation({ fixedCacheKey: 'kyb-reset' })
  const [createNewComment] = useCreateNewCommentBusinessMutation()
  const [updateIsPrivate] = useUpdateIsPrivateKybMutation()
  const [updateRiskLevel] = useUpdateRiskLevelMutation()
  const [updateCheck] = useUpdateCheckMutation({ fixedCacheKey: 'kyb-update-check' })
  const [createCheck, { isLoading: createCheckLoading, originalArgs: createCheckArgs }] = useCheckKybMutation()
  const [uploadFile, { isLoading: isProcessingFile }] = useUploadFileBusinessMutation()
  const [deleteFile, { isLoading: isProcessingFileDelete }] = useDeleteFileBusinessMutation()
  const [updateKybBusiness] = useUpdateKybMutation({ fixedCacheKey: 'kyb-update' })

  const { data: dataForAvailableUsers } = useFindAvailableUsersQuery()
  const availableUsers = useMemo(() => dataForAvailableUsers?.data || [], [dataForAvailableUsers])

  const handleRestart = async () => {
    try {
      await restartKyb({ countrySegment, kybId: id }).unwrap()
      toaster.success(t('messages:restartKybSuccess'))
      setOpenModalKybReset(false)
    } catch (error) {
      toaster.error(t('messages:restartKybFailure'))
      console.error(error?.data?.data)
    }
  }

  const handleUpdateKyb = async (field, value) => {
    try {
      await updateKybBusiness({ id, countrySegment, body: { [field]: value } }).unwrap()
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      toaster.error(t('messages:updateError'))
      console.error('[UPDATE KYB ERROR] --> ', error?.data?.data)
    } finally {
      handleFetch()
    }
  }

  const checks = useMemo(() => {
    if (!currentCountry) return []

    let sortedList = KybChecksDefaultOrder[currentCountry]['BUSINESS']

    if (userMasters?.kybChecks?.[currentCountry]?.['BUSINESS']) {
      sortedList = userMasters.kybChecks?.[currentCountry]?.['BUSINESS']
    }

    return KybChecks[currentCountry]['BUSINESS']
      ?.filter((kybCheck) => (!userMasters?.isNuclea ? kybCheck.title !== 'nuclea' : true))
      .sort((a, b) => sortedList.indexOf(a.title) - sortedList.indexOf(b.title))
  }, [userMasters?.kybChecks, userMasters?.isNuclea, currentCountry])

  const [expanded, setExpanded] = useState({})
  const [expandedMain, setExpandedMain] = useState([false, false, false, false, false, false])

  const CustomTabList = styled(TabList)({
    borderBottom: '1px solid #1C282026',
    '& ::-webkit-scrollbar-thumb': {
      background: theme === 'light' ? '#c9d2cc' : '#57635b',
    },
  })
  const currentDocumentNumber = useMemo(() => {
    return kybBusiness?.companyDetails?.documentNumber
  }, [kybBusiness])

  const timelineList = useMemo(() => {
    const timeline = kybBusiness?.timeline || []
    return [...timeline]?.reverse()
  }, [kybBusiness?.timeline])

  const handleChangeExpanded = (indexMain, index) => {
    const key = `${indexMain}-${index}`
    const newState = { ...expanded, [key]: !expanded[key] }
    setExpanded(newState)
  }
  const handleChangeExpandedMain = (indexMain) => {
    const newState = { ...expandedMain }
    newState[indexMain] = !newState[indexMain]
    setExpandedMain(newState)
  }
  const handleFetch = () => {
    setModalEditRiskLevel(false)
    setOpenModalCheckStatus(false)
    setModalEditStatus(false)
    setModalNewCommentOpen(false)
    setModalEditCluster(false)
    setOpenModalEditField(false)
  }

  const handleOpenModalEdit = useCallback(() => {
    setModalEditCluster(true)
  }, [setModalEditCluster])

  const handleOpenModalEditField = useCallback(
    (label, value) => {
      setModalEditField({ label, value: value ?? '' })
      setOpenModalEditField(true)
    },
    [setOpenModalEditField],
  )

  // STATUS KYB
  const handleUpdate = async (data) => {
    try {
      await updateKybBusiness({ id, countrySegment, body: data })
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      console.error('[UPDATE STATUS ERROR] --> ', error?.data?.data)
      toaster.error(error.message ? error.message : t('messages:updateError'))
    } finally {
      handleFetch()
    }
  }

  const handleIsPrivate = async () => {
    try {
      await updateIsPrivate({
        countrySegment,
        id,
        body: {
          isPrivate: !kybBusiness?.isPrivate,
        },
      }).unwrap()
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      console.error('[UPDATE STATUS ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:updateError'))
    } finally {
      handleFetch()
    }
  }

  // RISK LEVEL
  const handleUpdateRiskLevel = async (lvl) => {
    try {
      await updateRiskLevel({ countrySegment, id, lvl }).unwrap()
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      console.error('[UPDATE RISK LEVEL ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:updateError'))
    } finally {
      handleFetch()
    }
  }

  const handleCreateCheck = async (e, type) => {
    e.preventDefault()

    const requiresNameAndLast = type === 'procesos-judiciales-co' || type === 'fiscalia-co' || type === 'secop-co'
    const requiresOnlyLastName = type === 'inpec-co' || type === 'experian-hcpn-co'
    const splitName = kybBusiness?.name ? kybBusiness.name.split(' ') : []
    const lastName = splitName.length ? splitName[splitName.length - 1] : undefined
    const firstName = splitName.length ? splitName[0] : undefined

    let checkBody = {
      id: id,
      documentNumber: kybBusiness?.companyDetails?.documentNumber,
      entity: 'BUSINESS',
      type,
      ...(requiresNameAndLast && {
        name: firstName ?? removeLastWord(kybBusiness?.companyDetails?.name).modifiedString,
      }),
      ...((requiresOnlyLastName || requiresNameAndLast) && {
        last_name: lastName ?? removeLastWord(kybBusiness?.companyDetails?.name).lastWord,
      }),
      date_expedition:
        type === 'registraduria-co' || type === 'inhabilidades-co' || type === 'rmnc-co'
          ? kybBusiness?.companyDetails?.dateOfRegistration ?? '1994-05-03'
          : undefined,
    }
    checkBody = addAdditionalParams(type, checkBody)

    try {
      await createCheck({ countrySegment, body: checkBody }).unwrap()
      toaster.success(t('messages:creationSuccess'))
    } catch (error) {
      console.error('[CREATE NEW COMMENT ERROR] --> ', error?.data?.data)
      toaster.error(error.message ? error.message : t('messages:creationError'))
    } finally {
      handleFetch()
    }
  }

  const handleCheckUpdate = async (checkId, data) => {
    const body = addAdditionalParams(checkType, data)

    try {
      await updateCheck({ countrySegment, checkId, body: { ...body, entity: 'BUSINESS', entityId: id } }).unwrap()
      toaster.success(t('messages:updateSuccess'))
      setCheckId('')
      setCheckStatus('')
      setCheckType('')
    } catch (error) {
      console.error('[UPDATE STATUS ERROR] --> ', error?.data?.data)
      toaster.error(error.message ? error.message : t('messages:updateError'))
    } finally {
      handleFetch()
    }
  }

  // COMMENTS
  const postNewComment = async (newComment) => {
    try {
      await createNewComment({
        countrySegment,
        id,
        body: {
          emailClient: emailClient,
          email: email,
          description: newComment,
        },
      }).unwrap()
      toaster.success(t('messages:createCommentSuccess'))
      setModalNewCommentOpen(false)
    } catch (error) {
      console.error('[CREATE NEW COMMENT ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:createCommentError'))
    } finally {
      handleFetch()
    }
  }

  // FILES
  const handleUploadFile = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    try {
      await uploadFile({ countrySegment, id, body: formData }).unwrap()
      toaster.success(t('messages:uploadFileSuccess'))
    } catch (error) {
      toaster.error(t('messages:uploadFileError'))
      console.error('[UPLOAD KYB FILE ERROR] --> ', error?.data?.data)
    } finally {
      handleFetch()
    }
  }

  const handleDeleteFile = async (e, file) => {
    e.preventDefault()
    const splittedName = file.split('/kyb/')[1].split('?')
    const key = splittedName[splittedName.length - 2]

    try {
      await deleteFile({ countrySegment, id, key }).unwrap
      toaster.success(t('messages:deletionSuccess'))
    } catch (error) {
      console.error('[DELETE KYB FILE ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:deletionSuccess'))
    }
  }

  // ASSIGNS
  const updateAssigns = async (toAssign) => {
    try {
      await updateKybBusiness({
        id,
        countrySegment,
        body: {
          assignedUsers: toAssign,
        },
      }).unwrap()
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      console.error('[UPDATE CASE ASSIGNED USERS ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:editAssigUserError'))
    } finally {
      handleFetch()
    }
  }

  const finalAddress = useMemo(() => {
    if (Array.isArray(kybBusiness?.companyDetails?.addresses) && kybBusiness.companyDetails.addresses.length) {
      const aux = kybBusiness?.companyDetails?.addresses[0]

      return typeof aux === 'object'
        ? `${aux.street}, ${aux.number}, ${aux.city}, ${aux.state}, ${aux.postcode}, ${aux.country}`
        : aux
    }

    return ''
  }, [kybBusiness?.companyDetails?.addresses])

  const finalPhones = useMemo(() => {
    if (Array.isArray(kybBusiness?.companyDetails?.telefones) && kybBusiness?.companyDetails?.telefones?.length > 0) {
      return kybBusiness?.companyDetails?.telefones
        .filter((phone) => !!phone && (phone?.ddd || phone?.numero))
        .map((phone) => `${phone.ddd || ''} ${phone.numero || ''}`)
        .map((text) => text.trim())
        .join('; ')
    }

    if (Array.isArray(kybBusiness?.companyDetails?.phones) && kybBusiness?.companyDetails?.phones?.length > 0) {
      return kybBusiness?.companyDetails?.phones.filter((phone) => !!phone).join('; ')
    }

    return ''
  }, [kybBusiness?.companyDetails?.telefones, kybBusiness?.companyDetails?.phones])

  const companyDetails = AttributesByCountry[country]

  return (
    <Box className={`${(isFetching || isLoading) && 'spinner-transition'} filter`}>
      {/* Main KYB Detail Component */}
      <Box className="kyb-detail">
        {/* Header Section */}
        <Box className="kyb-detail-head">
          <Paper elevation={0} sx={{ mr: '1rem', padding: '1rem' }}>
            <Box className="kyb-info">
              <Box className="kyb-info-section-head">
                <Box className="kyb-info-section-1">
                  {/* Company/Person Name */}
                  <Typography variant="h4">
                    {kybBusiness?.companyDetails?.name ? kybBusiness.companyDetails.name : '---'}
                  </Typography>
                  {/* Account Type Chip */}
                  <Chip
                    label={t('common:businessAccount')}
                    icon={<WorkOutlineOutlinedIcon sx={{ fill: '#fbfffc' }} />}
                  />
                </Box>
              </Box>
              <Box className="kyb-info-section-2">
                <Box className="kyb-info-section-4 kyb-info-section-span">
                  {/* Document Number */}
                  <KybTitle
                    name={t('common:documentNumber')}
                    value={kybBusiness?.companyDetails?.documentNumber || '---'}
                    copyable
                  />

                  {/* Fantasia */}
                  {companyDetails.fantasyName && (
                    <KybTitle
                      name={t('common:fantasyName')}
                      value={deepGetByPath(kybBusiness, companyDetails.fantasyName) || '---'}
                    />
                  )}

                  {/* Situation */}
                  {companyDetails.status && (
                    <KybTitle
                      name={t('common:situation')}
                      value={deepGetByPath(kybBusiness, companyDetails.status) || '---'}
                    />
                  )}

                  {/* Date of Registration */}
                  <KybTitle
                    name={t('common:dateOfRegistration')}
                    value={kybBusiness?.companyDetails?.dateOfRegistration || '---'}
                  />
                </Box>

                <Box className="kyb-info-section-4 kyb-info-section-span">
                  {/* Social Capital */}
                  {companyDetails.socialCapital && (
                    <KybTitle
                      name={t('common:socialCapital')}
                      value={deepGetByPath(kybBusiness, companyDetails.socialCapital) || '---'}
                    />
                  )}

                  {/* Legal Nature */}
                  {companyDetails.companyLegalType && (
                    <KybTitle
                      name={t('common:companyLegalType')}
                      value={deepGetByPath(kybBusiness, companyDetails.companyLegalType) || '---'}
                    />
                  )}

                  {/* Type */}
                  {companyDetails.companyType && (
                    <KybTitle
                      name={t('common:companyType')}
                      value={deepGetByPath(kybBusiness, companyDetails.companyType) || '---'}
                    />
                  )}

                  {/* Cluster */}
                  <KybTitle
                    name={t('common:cluster')}
                    value={kybBusiness?.cluster ?? '---'}
                    onEditClick={handleOpenModalEdit}
                    editable
                  />

                  {/* site */}
                  <KybTitle
                    name={t('common:site')}
                    value={kybBusiness?.site ?? '---'}
                    onEditClick={() => handleOpenModalEditField('site', kybBusiness?.site)}
                    editable
                  />

                  {/* Size */}
                  {companyDetails.size && (
                    <KybTitle
                      name={t('common:size')}
                      value={deepGetByPath(kybBusiness, companyDetails.size) || '---'}
                    />
                  )}

                  {/* Simples Nacional */}
                  {companyDetails.simplesNacional && (
                    <KybTitle
                      name={t('common:simplesNacional')}
                      value={deepGetByPath(kybBusiness, companyDetails.simplesNacional) || '---'}
                    />
                  )}
                </Box>

                <Box className="kyb-info-section-3 kyb-info-section-span">
                  {/* CNAE Main */}
                  {(companyDetails.companyPrimaryCategoryCode || companyDetails.companyPrimaryCategoryDescription) && (
                    <KybTitle
                      name={t(`messages:kyb.companyCategoryMain.${country}`)}
                      value={[
                        `◎ ${deepGetByPath(kybBusiness, companyDetails.companyPrimaryCategoryCode) || '---'}: `,
                        deepGetByPath(kybBusiness, companyDetails.companyPrimaryCategoryDescription) || '---',
                      ]}
                    />
                  )}

                  {/* CNAE Secondary */}
                  {country === 'BR' && (
                    <KybTitle
                      name={t(`messages:kyb.companyCategorySecondary.${country}`)}
                      value={
                        kybBusiness?.companyDetails?.cnae?.secundarias?.length > 0
                          ? kybBusiness?.companyDetails?.cnae?.secundarias
                              ?.map((s) => `◎ ${s.subclasse}: ${s.descricao}`)
                              .join('\n')
                          : '---'
                      }
                    />
                  )}

                  {/* CNAE Secondary */}
                  {country !== 'BR' && (
                    <KybTitle
                      name={t(`messages:kyb.companyCategorySecondary.${country}`)}
                      value={
                        kybBusiness?.companyDetails?.companySecondaryCategories?.length > 0
                          ? kybBusiness?.companyDetails?.companySecondaryCategories
                              .map((s) => `◎ ${s.code}: ${s.description}`)
                              .join('\n')
                          : '---'
                      }
                    />
                  )}

                  {/* Address */}
                  <KybTitle
                    name={t('common:address')}
                    value={
                      finalAddress !== ''
                        ? finalAddress !== ', -, -, -, -, BR'
                          ? finalAddress
                          : 'Address not found.'
                        : 'Address not found.'
                    }
                    icon={
                      coordinates ? (
                        <Box className="kyb-address-icon">
                          <Link
                            href={`https://www.google.com/maps?q=${coordinates.lat},${coordinates.lng}&z=16`}
                            target="_blank"
                            variant="contained"
                            color="primary"
                          >
                            <Typography variant="number">{t('common:openMap')}</Typography>
                          </Link>
                        </Box>
                      ) : (
                        <></>
                      )
                    }
                  />
                </Box>

                <Box className="kyb-info-section-4 kyb-info-section-span">
                  {/* Legal Representative */}
                  <KybTitle
                    name={t('common:legalRepresentative')}
                    value={kybBusiness?.legalRepresentative ?? '---'}
                    onEditClick={() =>
                      handleOpenModalEditField('legalRepresentative', kybBusiness?.legalRepresentative)
                    }
                    editable
                  />

                  {/* Legal Representative Document Number */}
                  <KybTitle
                    name={t('common:legalRepresentativeDocumentNumber')}
                    value={kybBusiness?.legalRepresentativeDocumentNumber ?? '---'}
                    onEditClick={() =>
                      handleOpenModalEditField(
                        'legalRepresentativeDocumentNumber',
                        kybBusiness?.legalRepresentativeDocumentNumber,
                      )
                    }
                    editable
                  />

                  {/* Legal Representative Email */}
                  <KybTitle
                    name={t('common:legalRepresentativeEmail')}
                    value={kybBusiness?.legalRepresentativeEmail ?? '---'}
                    onEditClick={() =>
                      handleOpenModalEditField('legalRepresentativeEmail', kybBusiness?.legalRepresentativeEmail)
                    }
                    editable
                  />

                  {/* Legal Representative Phone */}
                  <KybTitle
                    name={t('common:legalRepresentativePhone')}
                    value={kybBusiness?.legalRepresentativePhone ?? '---'}
                    onEditClick={() =>
                      handleOpenModalEditField('legalRepresentativePhone', kybBusiness?.legalRepresentativePhone)
                    }
                    editable
                  />
                </Box>

                <Box className="kyb-info-section-4 kyb-info-section-span">
                  {/* Phone */}
                  <KybTitle name={t('common:phones')} value={finalPhones || '---'} />

                  {/* Email */}
                  <KybTitle
                    name={t('common:email')}
                    value={kybBusiness?.companyDetails?.emails?.length ? kybBusiness.companyDetails.emails[0] : '---'}
                  />

                  {/* Date of Creation */}
                  <KybTitle
                    name={t('common:creationDate')}
                    value={kybBusiness?.createdAt ? buildTimeStringFromTimestamp(kybBusiness.createdAt) : '---'}
                  />

                  {/* Date of last update */}
                  <KybTitle
                    name={t('common:updatedAt')}
                    value={kybBusiness?.updatedAt ? buildTimeStringFromTimestamp(kybBusiness.updatedAt) : '---'}
                  />
                </Box>

                {userMasters?.isNuclea && (
                  <Box className="kyb-info-section-4 kyb-info-section-span">
                    <KybTitle
                      name={t('messages:nuclea.agency')}
                      value={kybBusiness?.nuclea?.agency || '---'}
                      onEditClick={() => handleOpenModalEditField('nucleaAgency', kybBusiness?.nuclea?.agency)}
                      editable
                    />
                    <KybTitle
                      name={t('messages:nuclea.account')}
                      value={kybBusiness?.nuclea?.account || '---'}
                      onEditClick={() => handleOpenModalEditField('nucleaAccount', kybBusiness?.nuclea?.account)}
                      editable
                    />
                    <KybTitle
                      name={t('messages:nuclea.ispb')}
                      value={kybBusiness?.nuclea?.ispb || '---'}
                      onEditClick={() => handleOpenModalEditField('nucleaISPB', kybBusiness?.nuclea?.ispb)}
                      editable
                    />
                    <KybTitle
                      name={t('messages:nuclea.bank')}
                      value={kybBusiness?.nuclea?.bank || '---'}
                      onEditClick={() => handleOpenModalEditField('nucleaBank', kybBusiness?.nuclea?.bank)}
                      editable
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
          <Box className="details-sub">
            {roles.includes('MANAGER') && (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  justifyContent: 'flex-end',
                }}
              >
                <Switch
                  checked={kybBusiness?.isPrivate || false}
                  onChange={handleIsPrivate}
                  // disabled={!!isProcessing} TO-DO: update
                />
                <Typography variant="subtitle2">{t('common:private')}</Typography>
              </Box>
            )}
            <Button
              sx={{ width: '100%' }}
              size="small"
              onClick={() => setModalNewCommentOpen(true)}
              variant="contained"
            >
              {t('common:postComment')}
            </Button>
            {country === 'BR' && (
              <Button sx={{ width: '100%' }} size="small" onClick={() => setOpenModalKybReset(true)} variant="outlined">
                {t('common:reset')}
              </Button>
            )}
            <Button variant="outlined" onClick={() => setShowDownloadModal(true)} endIcon={<IosShareOutlinedIcon />}>
              <Typography variant="title2">{t('common:exportReport')}</Typography>
            </Button>
            <Paper elevation={0} sx={{ p: '1rem' }} className="risk-level">
              <Box className="risk-level-top">
                <Typography variant="subtitle2">{t('common:riskLevel')}</Typography>
                <Button size="small" onClick={() => setModalEditRiskLevel(true)} variant="outlined">
                  {t('common:edit')}
                </Button>
              </Box>
              {kybBusiness?.riskLevel ? (
                <RiskLevelSlider type={kybBusiness?.riskLevel} />
              ) : (
                <RiskLevelSlider type={'LOW'} />
              )}
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ 'aria-label': 'controlled' }}
                      checked={kybBusiness?.ignoreRiskMatrix ?? false}
                      onChange={() => handleUpdateKyb('ignoreRiskMatrix', !kybBusiness?.ignoreRiskMatrix)}
                    />
                  }
                  label={t('messages:kyb.ignoreRiskMatrix')}
                />
              </Box>
            </Paper>
            {userMasters?.isNuclea && (
              <Paper elevation={0} sx={{ p: '1rem' }} className="risk-level">
                <Box className="risk-level-top">
                  <Typography variant="subtitle2">{t('messages:kyb.nuclea.riskLevel')}</Typography>
                </Box>
                <Typography variant="h4">{kybBusiness?.nucleaKyc?.checkDetails?.score || '---'}</Typography>
              </Paper>
            )}
            <Paper elevation={0} sx={{ p: '1rem' }} className="status-level">
              <Box className="status-level-top">
                <Typography variant="subtitle2">{t('common:status')}</Typography>
                <Button size="small" onClick={() => setModalEditStatus(true)} variant="outlined">
                  {t('common:edit')}
                </Button>
              </Box>
              {kybBusiness?.status ? (
                <StatusIndicator type={kybBusiness?.status} color={colorsByStatus[kybBusiness.status]} />
              ) : (
                <StatusIndicator type={'NOT_STARTED'} />
              )}
            </Paper>
            {finalAddress !== '' && (
              <Box>
                <MapComponent fixedAddress={finalAddress} onCoordinatesFetched={setCoordinates} />
              </Box>
            )}
          </Box>
        </Box>
        <Box className="kyb-subdetail">
          <Box sx={{ paddingRight: '1rem' }}>
            <Paper className="kyb-detail-timeline" elevation={0} sx={{ padding: '1rem' }}>
              <Typography variant="h4">{t('common:timelineAndActivity')}</Typography>
              {Array.isArray(timelineList) && (
                <Box className="timeline-wrapper">
                  {timelineList.map((timeline) => {
                    const { color, backgroundColor } = getStyles(timeline.type)
                    return (
                      <Paper key={timeline._id} className="timeline-card" elevation={0} variant="colorSecondary">
                        <Box className="timeline-box">
                          <Box title={t(`common:${timeline.type}`)}>
                            <KybChip
                              action={t(`common:${timeline.type}`)}
                              color={color}
                              backgroundColor={backgroundColor}
                            />
                          </Box>
                        </Box>
                        {buildTimeStringFromTimestamp(timeline.createdAt)}
                        <Box className="timeline-box">
                          <p>{t('common:status')}:</p>
                          <Typography variant="text2">
                            {t(
                              `common:${
                                timeline.status === 'SUCCESS'
                                  ? 'ACTION_FINISHED_WITHOUT_ERROR'
                                  : timeline.status === 'ERROR'
                                  ? 'ACTION_FINISHED_WITH_ERROR'
                                  : timeline.status
                              }`,
                            )}
                          </Typography>
                        </Box>
                        {[
                          'SHAREHOLDER_CREATION',
                          'RELATEDPERSON_DELETION',
                          'RELATEDPERSON_CREATION',
                          'SHAREHOLDER_DELETION',
                        ].includes(timeline.type) &&
                          timeline.detail && (
                            <Box className="timeline-box">
                              <p>{t('common:details')}:</p>
                              <Typography variant="text2">
                                {`${t('common:documentN')}: ${timeline.detail?.documentNumber}`}
                              </Typography>
                            </Box>
                          )}
                        {['RELATED_COMPANIES_ADDED', 'RELATED_COMPANIES_REMOVED'].includes(timeline.type) && (
                          <Box className="timeline-box">
                            <p>{t('common:details')}:</p>
                            <Typography variant="text2">{timeline.description}</Typography>
                          </Box>
                        )}
                        <Box className="timeline-box">
                          <p>{t('common:operationalAgent')}:</p>
                          <Typography variant="text2">{timeline.email}</Typography>
                        </Box>
                      </Paper>
                    )
                  })}
                </Box>
              )}
            </Paper>
          </Box>
          <Box className="kyb-subgrid">
            <TabContext value={selectedTab}>
              <CustomTabList
                TabIndicatorProps={{ style: { display: 'none' } }}
                onChange={(e, newValue) => setSelectedTab(newValue)}
                variant="scrollable"
              >
                <Tab
                  label={
                    <Chip
                      label={t('common:checks')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'checks' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  sx={{ maxWidth: '100%' }}
                  value="checks"
                ></Tab>
                <Tab
                  label={
                    <Chip
                      label={t('common:documents')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'documents' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  value="documents"
                />
                {
                  <Tab
                    label={
                      <Chip
                        label={t('common:relatedPersons')}
                        sx={{ fontSize: 16 }}
                        variant={selectedTab === 'relatedPersons' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="relatedPersons"
                  />
                }
                <Tab
                  label={
                    <Chip
                      label={t('common:shareholders')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'shareHolders' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  value="shareHolders"
                />
                <Tab
                  label={
                    <Chip
                      label={t('common:assignedUsers')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'assignedUsers' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  value="assignedUsers"
                />
                <Tab
                  label={
                    <Chip
                      label={t('common:comments')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'comments' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  value="comments"
                />
              </CustomTabList>
              <Box>
                <TabPanel value="checks">
                  <Box className="kyb-checks-flex">
                    {checks?.map((mainChecks, indexMain) => (
                      <Accordion
                        key={indexMain}
                        expanded={expandedMain[indexMain]}
                        onChange={() => handleChangeExpandedMain(indexMain)}
                      >
                        <AccordionSummary expandIcon={null}>
                          <Box className="accordion-wrapper">
                            <Box className="accordion-status">
                              <Typography variant="title">
                                {t(`messages:kyb.checks.title.${mainChecks?.title}`)}
                              </Typography>
                              <Typography variant="subtitle3">
                                {t(`messages:kyb.checks.subtitle.${mainChecks?.subtitle}`)}
                              </Typography>
                            </Box>
                            <Button
                              variant="outlinedBlank"
                              onChange={(e) => {
                                e.stopPropagation()
                                return handleChangeExpandedMain(indexMain)
                              }}
                            >
                              {expandedMain[indexMain] ? (
                                <KeyboardDoubleArrowDownIcon />
                              ) : (
                                <KeyboardDoubleArrowUpIcon />
                              )}
                            </Button>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          {mainChecks?.items?.map((check, index) => {
                            const key = `${indexMain}-${index}`
                            return (
                              <Accordion
                                key={key}
                                expanded={expanded[key] || false}
                                onChange={(e) => {
                                  e.stopPropagation()
                                  handleChangeExpanded(indexMain, index)
                                }}
                                variant="medium"
                                slotProps={{ transition: { unmountOnExit: true } }}
                              >
                                <AccordionSummary expandIcon={null}>
                                  <Box className="accordion-wrapper">
                                    <Box className="accordion-status">
                                      <Typography variant="text2">{t(`common:${check.title}`)}</Typography>
                                      <Box>
                                        {kybBusiness?.[check.name] ? (
                                          <Box className="accordion-status-icon">
                                            <Status
                                              type={kybBusiness[check.name].status}
                                              noIcon={true}
                                              sizeBig={true}
                                              variant="colorSecondary"
                                            />
                                          </Box>
                                        ) : (
                                          <Box className="accordion-status-icon">
                                            <Status
                                              type={'NOT_STARTED'}
                                              noIcon={true}
                                              sizeBig={true}
                                              variant="colorSecondary"
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                    <Button
                                      variant="outlinedBlank"
                                      onChange={(e) => {
                                        e.stopPropagation()
                                        return handleChangeExpanded(index)
                                      }}
                                    >
                                      {expanded[index] ? (
                                        <KeyboardDoubleArrowDownIcon />
                                      ) : (
                                        <KeyboardDoubleArrowUpIcon />
                                      )}
                                    </Button>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box>
                                    {kybBusiness?.[check.name] ? (
                                      <KybCheckDetail name={check.name} value={kybBusiness[check.name]} />
                                    ) : (
                                      <Box>
                                        <LoadingButton
                                          size="small"
                                          variant="contained"
                                          color="secondary"
                                          loading={!!createCheckLoading && createCheckArgs?.body?.type === check.key}
                                          onClick={(e) => {
                                            handleCreateCheck(e, check.key)
                                          }}
                                        >
                                          <span>
                                            {t('common:run')} {camelCaseFormatter(check.name)}
                                          </span>
                                        </LoadingButton>
                                      </Box>
                                    )}
                                    <Box className="kyb-close">
                                      {kybBusiness?.[check.name] && (
                                        <Box className="kyb-check-status">
                                          <KybPreviousChecks
                                            kybId={id}
                                            type={check.key}
                                            checks={checks}
                                            countrySegment={countrySegment}
                                          />
                                          <Button
                                            size="small"
                                            fullWidth
                                            onClick={() => {
                                              setOpenModalCheckStatus(true)
                                              setCheckId(kybBusiness[check.name]?._id)
                                              setCheckStatus(kybBusiness[check.name]?.status)
                                              setCheckType(check.key)
                                            }}
                                            variant="contained"
                                          >
                                            {t('common:changeStatus')}
                                          </Button>
                                          <LoadingButton
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                            loading={!!createCheckLoading && createCheckArgs?.body?.type === check.key}
                                            onClick={(e) => handleCreateCheck(e, check.key)}
                                          >
                                            <span>
                                              {t('common:run')} {camelCaseFormatter(check.name)} {t('common:again')}
                                            </span>
                                          </LoadingButton>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            )
                          })}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </TabPanel>
                <TabPanel value="documents">
                  <Paper elevation={0}>
                    <Box className="kyb-detail-documents">
                      <Box className="kyb-documents-top">
                        <Typography variant="title">{t('common:documents')}</Typography>
                        <Typography variant="subtitle3">{t('messages:updatePDFRelated')}</Typography>
                      </Box>
                      <Box className="kyb-files-drag">
                        <Typography variant="title2">{t('common:addAFile')}</Typography>
                        <FileUploader
                          handleChange={(file) => handleUploadFile(file)}
                          name="file"
                          label={t('common:fileUploaderLabel')}
                          types={['JPG', 'JPEG', 'PNG', 'GIF', 'PDF', 'DOC', 'CSV']}
                        >
                          <Paper elevation={0} variant="rootDashedTransparent" className="draggable-main">
                            <Box className="draggable-icon"></Box>
                            <Typography variant="title2">{t('common:fileUploaderLabel')}</Typography>
                            <Box>
                              <Typography variant="subtitle4">{t('common:or')} </Typography>
                              <Typography sx={{ textDecoration: 'underline' }} variant="subtitle4" color="secondary">
                                {t('common:browseMedia')}
                              </Typography>
                            </Box>
                          </Paper>
                        </FileUploader>
                        <Typography variant="subtitle4">
                          {t('common:supportedMedia')}: JPG, PNG, GIF, MP4, MPEG, WEBM, MP3, WAV, PDF.
                        </Typography>
                      </Box>
                      <Box className="kyb-file-list-wrapper">
                        <Typography variant="title"> {t('common:uploadedDocuments')}</Typography>
                        <Box className="kyb-file-list">
                          {isProcessingFile || isProcessingFileDelete ? (
                            <ProcessingSpinner message={t('common:processing')} />
                          ) : (
                            <Box>
                              {Array.isArray(kybBusiness?.files) && kybBusiness.files.length > 0 && (
                                <Box className="kyb-files">
                                  {typeof kybBusiness?.files[0] === 'string' &&
                                    kybBusiness.files.map((file, index) => {
                                      const splittedName = file.split('/kyb/')[1].split('_')
                                      const fileName = splittedName[splittedName.length - 2]?.replaceAll('%20', ' ')
                                      return (
                                        <Paper elevation={0} variant="colorPrimary" className="kyb-file" key={index}>
                                          <Box className="kyb-file-description">
                                            <Box className="color-button">
                                              <Typography variant="subtitle1" sx={{ color: '#FF2B1B' }}>
                                                DOC
                                              </Typography>
                                            </Box>
                                            <Typography variant="text2" title={fileName}>
                                              {fileName}
                                            </Typography>
                                          </Box>
                                          <Box className="kyb-file-icons">
                                            <Button
                                              variant="outlined"
                                              size="small"
                                              onClick={() => window.open(file, '_blank')}
                                            >
                                              {t('common:view')}
                                            </Button>
                                            <Button variant="outlinedBlank" onClick={(e) => handleDeleteFile(e, file)}>
                                              <DeleteOutlineIcon />
                                            </Button>
                                          </Box>
                                        </Paper>
                                      )
                                    })}
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </TabPanel>
                <TabPanel value="shareHolders">
                  <Paper elevation={0}>
                    <TabComponent
                      entityType={'BUSINESS'}
                      subjectType="shareHolder"
                      lastBeneficiaries={kybBusiness?.lastBeneficiaries}
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      documentNumber={currentDocumentNumber}
                      handleFetch={handleFetch}
                    />
                  </Paper>
                </TabPanel>
                <TabPanel value="relatedPersons">
                  <Paper elevation={0}>
                    <TabComponent
                      entityType={'BUSINESS'}
                      subjectType="relatedPerson"
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      documentNumber={currentDocumentNumber}
                      handleFetch={handleFetch}
                    />
                  </Paper>
                </TabPanel>
                <TabPanel value="assignedUsers">
                  <Paper elevation={0}>
                    <Box className="kyb-card">
                      <Box className="card-top">
                        <Typography variant="title">{t('common:assignedUsers')}</Typography>
                        {!isEditingAssign ? (
                          <Button
                            onClick={() => {
                              setIsEditingAssign(true)
                            }}
                            size="small"
                            variant="contained"
                          >
                            {t('common:addUser')}
                          </Button>
                        ) : (
                          <Fab variant="close" onClick={() => setIsEditingAssign(false)}>
                            <CloseOutlinedIcon />
                          </Fab>
                        )}
                      </Box>
                      <DatatableAssignUsers
                        rows={kybBusiness?.assignedUsers || []}
                        toAssign={availableUsers}
                        isEditing={isEditingAssign}
                        updateAssigns={updateAssigns}
                        setIsEditingAssign={setIsEditingAssign}
                      />
                    </Box>
                  </Paper>
                </TabPanel>
                <TabPanel value="comments">
                  <Paper elevation={0}>
                    <Box className="kyb-card">
                      <Box className="card-top">
                        <Typography variant="title">{t('common:comments')}</Typography>
                      </Box>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('common:email')}</TableCell>
                              <TableCell>{t('common:createdAt')}</TableCell>
                              <TableCell>{t('common:comment')}</TableCell>
                            </TableRow>
                          </TableHead>

                          {Array.isArray(kybBusiness?.comments) && kybBusiness?.comments?.length > 0 ? (
                            kybBusiness.comments.map((row, index) => (
                              <TableBody key={index}>
                                <TableRow>
                                  <TableCell>
                                    <Box>{row.email ?? '---'}</Box>
                                  </TableCell>
                                  <TableCell>
                                    {row.createdAt ? buildTimeStringFromTimestamp(row.createdAt) : '---'}
                                  </TableCell>
                                  <TableCell>{row.description ?? '---'}</TableCell>
                                </TableRow>
                              </TableBody>
                            ))
                          ) : (
                            <Box className="table-custom-overlay">
                              <CustomNoRowsOverlay />
                            </Box>
                          )}
                        </Table>
                      </TableContainer>
                    </Box>
                  </Paper>
                </TabPanel>
              </Box>
            </TabContext>
          </Box>
        </Box>

        <KybModal title="" open={showDownloadModal} setOpen={setShowDownloadModal}>
          <KybToPdf kyb={kybBusiness} />
        </KybModal>

        <KybModal
          title={t('common:postComment')}
          subtitle={t('messages:commentDesc')}
          open={modalNewCommentOpen}
          setOpen={setModalNewCommentOpen}
        >
          <KybNewCommentModal onSubmit={postNewComment} />
        </KybModal>

        <KybModal
          title={`${t('common:edit')} KYB ${t('common:status')}`}
          open={modalEditStatus}
          setOpen={setModalEditStatus}
        >
          <KybEditStatusModal defaultValue={kybBusiness?.status || 'NOT_STARTED'} onSubmit={handleUpdate} />
        </KybModal>

        <KybModal
          title={`${t('common:edit')} ${t('common:check')} ${t('common:status')}`}
          open={openModalCheckStatus}
          setOpen={setOpenModalCheckStatus}
        >
          <KybEditCheckStatusModal defaultValue={checkStatus} checkId={checkId} onSubmit={handleCheckUpdate} />
        </KybModal>

        <KybModal
          title={`${t('common:edit')} KYB ${t('common:riskLevel')}`}
          open={modalEditRiskLevel}
          setOpen={setModalEditRiskLevel}
        >
          <KybEditRiskLevelModal initialValue={kybBusiness?.riskLevel || 'LOW'} onSubmit={handleUpdateRiskLevel} />
        </KybModal>

        <KybModal title={t('common:EDIT_CLUSTER')} open={modalEditCluster} setOpen={setModalEditCluster}>
          <KybClusterModal initialValue={kybBusiness?.cluster ?? ''} onSubmit={handleUpdateKyb} />
        </KybModal>

        <KybModal
          title={`${t('common:edit')} ${t(`common:${modalEditField.label}`)}`}
          open={openModalEditField}
          setOpen={setOpenModalEditField}
        >
          <KybEditFieldModal
            label={modalEditField.label}
            initialValue={modalEditField.value}
            onSubmit={handleUpdateKyb}
          />
        </KybModal>
        <KybModal title={t('messages:reset.kyb.modal.title')} open={openModalKybReset} setOpen={setOpenModalKybReset}>
          <KybResetModal onSubmit={handleRestart} mutationKey="kyb-reset" />
        </KybModal>
      </Box>
    </Box>
  )
}

export default KybDetailBusiness
